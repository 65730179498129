import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {Link, animateScroll as scroll} from 'react-scroll';

import Modal from 'components/Modal';
import ProductImage from 'components/Elements/ProductImage';
import useExitIntent from 'utils/useExitIntent';

export default function ModalExitSales(props) {
  const {isOpen, toggle} = useExitIntent(60000);

  return (
    <Modal className="modal-exit-intent modal-exit-intent-empowerment" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h4 className="section-heading mb-4">Don’t go yet!</h4>
          <p className="mb-0">
            In Unlocking Longevity, you’ll learn what <br className="d-none d-md-block d-lg-none" />
            you can <br className="d-none d-sm-block d-md-none" />
            do to greatly enhance your odds of increasing not <br className="d-none d-sm-block d-md-none" />
            just your life span by about 15 years but, even <br className="d-none d-sm-block d-md-none" />
            more importantly, your health span.
          </p>
          <Row className="d-flex align-items-center mt-5">
            <Col xs="12" lg="6" className="mb-4 mb-lg-0 pr-lg-0">
              <Button
                className="text-uppercase text-white btn-exit-orange btn-exit-ul-join"
                color="cta"
                onClick={toggle}
                block
                activeClass="active"
                spy={true}
                offset={-55}
                to="offer-cta-2"
                smooth={true}
                href="#"
                tag={Link}
              >
                Take Me Back <br className="d-none d-lg-block" />
                to the Course
              </Button>
            </Col>
            <Col xs="12" lg="6">
              <Button className="text-uppercase text-800 text-white" color="exit-gray" onClick={toggle} block>
                No thanks, <br className="d-none d-lg-block" />
                I’m not <br className="d-md-none" />
                interested <br className="d-none d-lg-block" />
                in longevity
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="5" className="landing-exit-image-col background-light-gray order-lg-1 d-flex flex-column">
          <div className="my-auto px-3 px-lg-1 py-5">
            <ProductImage />
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import MasterClassIndex from './masterclass/index';

export default function (props) {
  useEffect(() => {
    navigate('/a/masterclass/prevent/' + location.search);
  }, []);
  return <MasterClassIndex noFixedVideo noExitIntent variant="ad" />;
}

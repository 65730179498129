import React, {useEffect} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import queryString from 'query-string';
import classNames from 'classnames';

import track from 'utils/track';
import useBalanceText from 'utils/useBalanceText';
import {useUserState} from 'contexts/user';

import PageMeta from 'components/PageMeta';
import SectionCL from 'components/ComponentLibrary/SectionCL';
import ScrollingVideo from 'components/ComponentLibrary/ScrollingVideo';
import ImageCardCL from 'components/ComponentLibrary/ImageCardCL';

import CheckoutButton from 'components/CheckoutButton';
import {SalesDisclaimer} from 'components/SalesCta';
import Footer from 'components/Elements/Footer';

// @ts-ignore
import data from 'data/wlc-upsell.json';
import TestimonialCL from 'components/ComponentLibrary/TestimonialCL';
import IconListCL from 'components/ComponentLibrary/IconListCL/IconListCL';
const {wlcTestimonials, wlcRecipe} = data;

const PRODUCT_SKU = 'WLC';
const PRODUCT_NAME = 'WHOLE Life Club';
const PRODUCT_PRICE = 0;
const PRODUCT_CHECKOUT_SLUG = 'hf-ul-wlc-us';

export default function WlcUpsell({product = 'ul', trialLength = '30day'}) {
  const pageTitle = 'Join WHOLE Life Club';
  const pageDescription = 'Make FOOD the foundation of YOUR health';
  const ogImage = 'https://cdn.foodrevolution.org/global/images/placeholder.jpg';
  const shareUrl = 'https://earth.foodrevolution.org/';

  const userState = useUserState();
  const {
    OrderId = '',
    OfferSlug = '',
    UpSellId = ''
  } = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  const successUrl = `${process.env.CHECKOUT_URL}/${OfferSlug}/${OrderId}/${UpSellId}/accept`;
  const declineUrl = `${process.env.CHECKOUT_URL}/${OfferSlug}/${OrderId}/${UpSellId}/decline`;

  const trial14Day = trialLength === '14day';

  useEffect(() => {
    track('Product Viewed', {
      product_id: PRODUCT_SKU,
      sku: PRODUCT_SKU,
      name: PRODUCT_NAME,
      value: Number(PRODUCT_PRICE).toFixed(2),
      variant: PRODUCT_CHECKOUT_SLUG,
      currency: 'usd'
    });
  }, []);

  function CtaSection({successUrl, declineUrl}) {
    console.log(successUrl, declineUrl);
    return (
      <div className="background-white text-center p-5 rounded">
        <h3 className="section-heading">Try it free — click below to add to your order.</h3>
        <h3 className="mt-0">
          <i>This offer is ONLY available from this page, so please don’t hit refresh.</i>
        </h3>
        <CheckoutButton
          sku={PRODUCT_SKU}
          price={Number(PRODUCT_PRICE).toFixed(2)}
          slug={PRODUCT_CHECKOUT_SLUG}
          btnText="Yes, Add to Order"
          badge
          checkoutUrl={process.env.CHECKOUT_URL ?? ''}
          href={successUrl}
        />
        <p className="mt-4">You’ll get {trial14Day ? '14' : '30'} days to try WHOLE Life Club, free.</p>
        <p>
          Clicking the button above will sign you up for WHOLE Life Club for your free {trial14Day ? '14' : '30'} day
          trial. After that, your membership will renew for a massively discounted $197/year (a 60% savings!) unless you
          cancel before your trial expires. That’s just 54 cents a day! This price is locked in and will never go up, so
          long as you keep your membership active. Cancel any time.
        </p>
        <Button className="btn-upsell-gray mt-4 mb-3" href={declineUrl}>
          No, thanks.
        </Button>
        <p>Clicking the “No” button above will take you to the products already in your cart.</p>
        <SalesDisclaimer noGuaranteeText />
      </div>
    );
  }

  const healthFilmPartner = {ff: 'Louie', eft: 'Eating Our Way to Extinction LTD'}[product];
  const healthFilmName = {ff: 'Fantastic Fungi', eft: 'Eating For Tomorrow'}[product];
  const healthFilmProductType = {ff: 'Impact Kit', eft: 'Impact Kit'}[product];

  useBalanceText();
  return (
    <div className={classNames(`page`, `page-nosocial`, `d-flex`, `flex-column`)}>
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <main className="page-content">
        <SectionCL
          id="section-wlc-header"
          color="black"
          className="text-white text-center"
          order="first"
          type="triangle"
        >
          <Container>
            <Row className="d-flex justify-content-center mb-4">
              <Col>
                {((product) => {
                  switch (product) {
                    case 'eft':
                      return (
                        <>
                          <p className="balance-text">
                            <i>Exciting things are on the way!</i>
                            <br />
                            Congratulations
                            {userState && userState.user && userState.user.firstName && `, ${userState.user.firstName}`}
                            ! Your log-in details will arrive in your inbox momentarily.
                          </p>
                          <h4 className="mt-3 mb-0">
                            Now, here’s a special one-time opportunity to get delicious plant-based recipes, expert
                            mentorship, and supportive community with a totally free 30-day trial!
                          </h4>
                        </>
                      );
                    case 'fda':
                      return (
                        <>
                          <p className="balance-text">
                            <i>Exciting things are on the way!</i>
                            <br />
                            Your first 14-Day Plant-Powered Accelerator email will reach your inbox in a few minutes.
                          </p>
                        </>
                      );
                    case 'frs':
                      return (
                        <>
                          <p>
                            <i>Exciting things are on the way!</i>
                            <br />
                            Check your email for instructions to access your Empowerment Package.
                          </p>
                        </>
                      );
                    case 'ff':
                      return (
                        <>
                          <p>
                            Congratulations, [First Name]! Your log-in details will arrive in your inbox momentarily.
                          </p>
                          <p>
                            The <i>Fantastic Fungi Impact Kit</i> gives you a deep dive into the kingdom of mushrooms.
                            It will show you how you can use fungi to uplift your health and help build a healthy
                            planet.
                          </p>
                          <h4 className="mt-3 mb-0">
                            Now here’s a special opportunity to take the next step — with a totally free 30-day trial.
                          </h4>
                        </>
                      );
                    case 'ppp':
                      return (
                        <>
                          <p>
                            Success! Your <i>Plant-Powered Playbook</i> Log-In Details Will Arrive in Your Inbox
                            Momentarily.
                          </p>
                        </>
                      );
                    case 'tt2':
                      return (
                        <>
                          <p>
                            <i>Exciting things are on the way!</i>
                            <br />
                            Success! Your <i>Tackling Type 2</i> Log-In Details Will Arrive in Your Inbox Momentarily.
                          </p>
                        </>
                      );
                    case 'ul':
                      return (
                        <p>Success! Your Unlocking Longevity Log-In Details Will Arrive in Your Inbox Momentarily.</p>
                      );
                    default:
                      return null;
                  }
                })(product)}

                {product === 'ff' ? null : (
                  <>
                    <h3 className="mt-3 mb-0">
                      Before you go, do you want a <i>free {trial14Day ? '2 weeks' : 'month'}</i> in WHOLE Life Club?
                    </h3>
                  </>
                )}
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                {product === 'ff' ? null : (
                  <div className="px-3 px-sm-0">
                    <h5 className="section-heading text-uppercase mt-3">
                      Watch This Important Message From Ocean Robbins:
                    </h5>
                  </div>
                )}
                <ScrollingVideo
                  video={{
                    url: {
                      eft: 'https://www.youtube.com/watch?v=',
                      fda: 'https://www.youtube.com/watch?v=',
                      frs: trial14Day
                        ? 'https://www.youtube.com/watch?v='
                        : 'https://www.youtube.com/watch?v=c_PzvV5Pjbs',
                      ff: 'https://www.youtube.com/watch?v=CBn38igDG-4',
                      ftf: 'https://www.youtube.com/watch?v=malOFWQg-Rs',
                      ppp: 'https://www.youtube.com/watch?v=',
                      tt2: 'https://www.youtube.com/watch?v=dkxrTTbcHlg',
                      ul: 'https://www.youtube.com/watch?v=cyRwF8uADag'
                    }[product],
                    label: `${product} - WLC Upsell`,
                    playing: true
                  }}
                  text={
                    <>
                      <p className="text-h5 text-800">Try WHOLE Life Club For Free Today</p>
                    </>
                  }
                  button={{
                    scrollToSection: 'section-wlc-offer-2',
                    text: 'Add to Order'
                  }}
                />
              </Col>
            </Row>

            {((product) => {
              switch (product) {
                case 'collections':
                case 'fda':
                case 'tt2':
                  return (
                    <Row className="text-center mt-5">
                      <Col>
                        {product === 'collections' ? (
                          <p className="balance-text">
                            The [insert health collection purchased] jumpstarts your journey to your best health. But
                            make sure you don’t slip or lose momentum…
                          </p>
                        ) : null}
                        {product === 'fda' ? (
                          <p className="balance-text">
                            The 14-Day Plant-Powered Accelerator kicks off your healthy eating journey. But make sure
                            you don’t slip after 2 weeks…
                          </p>
                        ) : null}
                        {product === 'tt2' ? null : (
                          <h3 className="mt-0">
                            WHOLE Life Club is the #1 way to make healthy habits stick, <i>for LIFE.</i>
                          </h3>
                        )}
                      </Col>
                    </Row>
                  );
                default:
                  return null;
              }
            })(product)}
          </Container>
        </SectionCL>

        {product === 'eft' || product === 'tt2' || product === 'ppp' || product === 'ul' || product === 'frs' ? (
          <SectionCL id="" color="light-gray" type="triangle">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="10">
                  {((product) => {
                    switch (product) {
                      case 'eft':
                        return (
                          <>
                            <p className="balance-text">
                              The <i>Eating For Tomorrow</i> Impact Kit kicks off your journey to be a part of the
                              solution with plant-based foods.
                            </p>
                            <p className="balance-text">
                              However, without ongoing support, you run the risk of stalling out or falling back into
                              old habits.
                            </p>
                            <p className="balance-text">
                              And many people who go plant-based don’t know how to do it right — so they end up swapping
                              meat and dairy for vegan junk food, becoming protein deficient, or missing out on critical
                              nutrients.
                            </p>
                            <p className="balance-text">
                              That’s why we created WHOLE Life Club, and we know you’re going to love it.
                            </p>
                            <p className="balance-text">
                              Keep reading to learn why our members call it a “lifesaver”...
                            </p>
                            <p className="balance-text">
                              And <b>get a special opportunity</b> ONLY for Impact Kit owners, and ONLY available from
                              this page.
                            </p>
                          </>
                        );
                      case 'frs':
                        return (
                          <>
                            <p className="balance-text">
                              When you put what you learn in the Food Revolution Summit into action, you’re going to see
                              incredible transformations.
                            </p>
                            <p className="balance-text">
                              However, in a world that’s rapidly evolving, you need a way to stay up to date on the
                              latest health research.
                            </p>
                            <p className="balance-text">
                              Without a way to stay current, you could miss out on vital information to keep you and
                              your family safe, now or in the future.
                            </p>
                            <p className="balance-text">
                              And without ongoing support, it’s easy to fall back into old habits.
                            </p>
                            <p className="balance-text">
                              That puts you at risk for chronic diseases, weight gain, and a life spent on the couch,
                              thinking, “Maybe one day I’ll have energy again…”
                            </p>
                            <p className="balance-text">
                              It’s totally possible to feel better in your 50s, 60s, and 70s+ than you did in your 20s.
                            </p>
                            <p className="balance-text">We’ll show you how it’s done inside WHOLE Life Club!</p>
                            <h3 className="mt-4 mb-0">
                              WHOLE Life Club is the #1 way to make healthy habits stick, <i>for LIFE.</i>
                            </h3>
                          </>
                        );
                      case 'ppp':
                        return (
                          <>
                            <p className="balance-text">
                              You’ve just taken an incredible step in the right direction by getting the{' '}
                              <i>Plant-Powered Playbook!</i>
                            </p>
                            <p className="balance-text">
                              When you put the <i>Plant-Powered Playbook</i> into action, you’re going to learn, make
                              changes, and see incredible transformations.
                            </p>
                            <p className="balance-text">
                              However, without long-term support, you run the risk of stalling out or falling back into
                              old habits.
                            </p>
                            <p className="balance-text">
                              That puts you at risk for chronic diseases, weight gain, and a life spent on the couch,
                              thinking, “Maybe one day I’ll have enough energy again…”
                            </p>
                            <p className="balance-text">
                              OR you can plan ahead now, knowing that you’ll want to keep making progress, keep learning
                              all the ways to be healthy…
                            </p>
                            <p>
                              <b>And give yourself a way to live an extraordinarily healthy life.</b>
                            </p>
                            <p>
                              It’s totally possible to feel better in your 40s, 50s, and 60s+ than you did in your 20s.
                            </p>
                            <p>To eat for excellent immune health, mental health, and maximum energy.</p>
                            <p>You already know that food and lifestyle can make a world of difference.</p>
                            <p className="balance-text">
                              <b>
                                But do you know exactly what to eat and do — based on the latest scientific research
                                (and how to ignore all the fads that waste your time and could even be dangerous)?
                              </b>
                            </p>
                            <p className="balance-text">
                              Would you like to do a little better day by day… until you’re the healthiest you possible?
                            </p>
                            <p className="balance-text">
                              And do you want a place to get all your questions answered by certified health coaches and
                              nutritionists?
                            </p>
                            <p>We’ll show you how it’s done inside WHOLE Life Club!</p>
                          </>
                        );
                      case 'tt2':
                        return (
                          <>
                            <p>
                              You’ve just taken an incredible step in the right direction by joining{' '}
                              <i>Tackling Type 2!</i>
                            </p>
                            <p className="balance-text">
                              When you put <i>Tackling Type 2</i> into action, you're going to learn, make changes, and
                              see incredible transformations.
                            </p>
                            <p className="balance-text">
                              However, without long-term support, you run the risk of stalling out or falling back into
                              old habits.
                            </p>
                            <p>You already know that food and lifestyle can make a world of difference.</p>
                            <p className="balance-text">
                              <b>
                                But do you know exactly what to eat and do — based on the latest scientific research
                                (and how to ignore all the fads that waste your time and could even be dangerous)?
                              </b>
                            </p>
                            <p className="balance-text">
                              Would you like to do a little better day by day… until you’re the healthiest you possible?
                            </p>
                            <p>We’ll show you how it’s done inside WHOLE Life Club!</p>
                            <h3 className="mt-4 mb-0">
                              WHOLE Life Club is the #1 way to make healthy habits stick, <i>for LIFE.</i>
                            </h3>
                          </>
                        );
                      case 'ul':
                        return (
                          <>
                            <p className="balance-text">
                              You’ve just taken an incredible step in the right direction by enrolling in Unlocking
                              Longevity!
                            </p>
                            <p className="balance-text">
                              When you put this course into action, you’re going to learn, make changes, and see
                              incredible transformations.
                            </p>
                            <p className="balance-text">
                              However, without ongoing support, you run the risk of stalling out or falling back into
                              old habits.
                            </p>
                            <p className="balance-text">
                              That’s why we created WHOLE Life Club, and we know you’re going to love it.
                            </p>
                            <p className="balance-text">
                              Keep reading to learn why our members call it a “lifesaver”...
                            </p>

                            <p className="balance-text">
                              And <b>get a special opportunity ONLY</b> available from this page.
                            </p>
                          </>
                        );
                      default:
                        return null;
                    }
                  })(product)}
                </Col>
              </Row>
            </Container>
          </SectionCL>
        ) : null}

        <SectionCL id="section-wlc-offer-1" color="purple" type="triangle">
          <Container>
            <Row className="mt-4">
              <Col>
                <h2 className="section-heading text-white text-center text-uppercase mb-5">
                  {((product) => {
                    switch (product) {
                      case 'eft':
                        return (
                          <>
                            One-Time Offer for <i>Eating For Tomorrow</i> Impact Kit Owners Only
                          </>
                        );
                      case 'ff':
                        return (
                          <>
                            One-Time Offer for <i>Fantastic Fungi Impact Kit</i> Owners Only
                          </>
                        );
                      default:
                        return <>One-Time Offer</>;
                    }
                  })(product)}
                </h2>
                <CtaSection successUrl={successUrl} declineUrl={declineUrl} />
              </Col>
            </Row>
          </Container>
        </SectionCL>

        {((product) => {
          switch (product) {
            case 'eft':
            case 'ff':
              return (
                <SectionCL id="section-wlc-frn" color="light-gray" innerClass="text-center" type="triangle">
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg="10">
                        <img
                          src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                          alt="Food Revolution Network logo"
                          width={238}
                          height={100}
                          className="mb-4"
                        />
                        <h4 className="section-heading">
                          <i>What is Food Revolution Network?</i>
                        </h4>
                        <p className="balance-text">
                          For over a decade, we’ve been at the forefront of the healthy living movement. We and our
                          million members are committed to healthy, ethical, and sustainable food for all. We’ve
                          partnered with {healthFilmPartner} and the <i>{healthFilmName}</i> team to bring this film
                          into the world and to create and share the {healthFilmProductType}.
                        </p>
                        <p className="balance-text">
                          We want to make sure you know how to eat to prevent and reverse disease — and transform how
                          you look and feel each day.
                        </p>
                        <p className="balance-text">
                          <b>WHOLE Life Club</b> is our premium membership community. It’s the place to go if you want
                          recipes, community, and the latest wisdom to help you implement, sustain, and optimize a
                          healthy lifestyle for maximal health-boosting results.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </SectionCL>
              );
            default:
              return null;
          }
        })(product)}

        <SectionCL id="section-wlc-secret" color="white" type="triangle">
          <Container>
            <Row>
              <Col>
                <p className="balance-text text-center text-16 mb-5">
                  <i>
                    After joining WHOLE Life Club, many participants have adopted a whole foods, plant-centered dietary
                    pattern. Their testimonials reflect their unique experience. Your specific results will vary.
                  </i>
                </p>
                {[
                  'wlcJuanita',
                  'wlcKathyWShort',
                  'wlcHeidiH',
                  'wlcRobinShort',
                  'wlcTinaG',
                  'wlcCindy',
                  'wlcMaureen'
                ].map((key) => (
                  <TestimonialCL {...wlcTestimonials[key]} key={key} />
                ))}
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="whole-recipes" color="light-gray" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-center mb-5">1000+ DELICIOUS, HEALTHY PLANT-BASED RECIPES</h2>
              </Col>
            </Row>
            <Row>
              {wlcRecipe.map((wlcRecipe) => (
                <Col lg="6" key={wlcRecipe.id}>
                  <ImageCardCL key={wlcRecipe.id} image={wlcRecipe.image} imageAlt={wlcRecipe.imageAlt}>
                    {wlcRecipe.name}
                  </ImageCardCL>
                </Col>
              ))}
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="whole-more-2" color="white" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Weekly Videos From Ocean Robbins & The Food Revolution Network Team
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img
                  className="img-border rounded"
                  src="https://cdn.foodrevolution.org/wlc/upsell/action-of-the-week.jpg"
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  No one is born with an instruction manual on how to eat and what to do — but when you join the WHOLE
                  Life Club, you can be sure you won’t miss out on the most important information.
                </p>
                <p>
                  These Weekly Guides are like getting a private coaching session with Ocean and the Food Revolution
                  Network team each week, where they share the truth on MUST-KNOW topics that can transform your life.
                </p>
                <p>
                  You’ll get dispatches on topics like healthy versus unhealthy fats, adrenals, immune-supporting herbs,
                  adaptogens, fitness hacks, cancer-kicking foods, the truth about vitamins and minerals, practical tips
                  like how to say “no” and stay positive, and so much more.
                </p>
                <p>
                  With the Food Revolution Network team by your side, you’ll stay vibrant and motivated while taking
                  action to be your best YOU.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="whole-more-2" color="light-gray" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">Curated (Delicious) Recipes</h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img src="https://cdn.foodrevolution.org/wlc/upsell/recipe.png" />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  No more getting stuck in food ruts, eating the same meals over and over. Each week, you’ll get new
                  recipes that dovetail perfectly with the weekly topics.
                </p>
                <p>
                  Discover superstars of nutrition with exciting recipes that showcase new ways to delight your taste
                  buds (and your family, too)!
                </p>
                <p>
                  For example, when you learn about zinc and how it has been found to shorten colds, you’ll get
                  zinc-rich recipes like <i>Ginger Miso Dressing,</i> <i>Creamy Lentil Spinach Dahl,</i> and{' '}
                  <i>Chocolate Almond Mousse.</i>
                </p>
                <p>
                  Or, when the topic is avoiding anemia, you can enjoy adding more healthy non-heme iron to your plate
                  with recipes like <i>Orange-Miso-Glazed Tofu</i> and <i>Chocolate Raspberry Smoothie.</i>
                </p>
                <p>
                  Each recipe is a super-healthy crowd-pleaser — always vegan, with gluten-free, soy-free, and salt-free
                  options — and mega-delicious so everyone can partake.
                </p>
                <p>
                  The instant you join, you’ll have the entire collection of amazing WHOLE Life Club recipes at your
                  fingertips.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="whole-more-3" color="white" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Monthly Action Hours with Leading Experts
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img src="https://cdn.foodrevolution.org/wlc/upsell/action-hour.png" />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  Would you like front-row seats to the latest scientific discoveries from doctors who are{' '}
                  <i>New York Times</i> best-selling authors and world-famous health experts?
                </p>
                <p>
                  As a WHOLE Life Club member, you’ll be treated to monthly Action Hours with health heroes who can’t
                  wait to share their decades of research and wisdom with you.
                </p>
                <p>
                  Get the scoop on topics such as longer lifespans, eating to defeat cancer, ending food addiction,
                  conquering autoimmune disease, healthy hormones, calming anxiety naturally, habits to avoid heart
                  disease, and more. (And you can ask YOUR questions, too!)
                </p>
                <p>
                  Plus, the instant you join WHOLE Life Club, you’ll have access to our entire library of 60+ Action
                  Hours.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="whole-more-4" color="light-gray" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-uppercase text-center">
                  Access to Registered Dieticians and Brilliant Health Coaches
                </h2>
              </Col>
            </Row>
            <Row className="d-flex align-items-center justify-content-center">
              <Col sm="10" lg="8" className="mb-4">
                <img src="https://cdn.foodrevolution.org/wlc/upsell/coaches.png" />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10">
                <p>
                  Get answers to your most important health and nutrition questions — <i>finally!</i>
                </p>
                <p>
                  If you’ve ever tried to find answers on YouTube or blogs, then you know how frustrating it can be to
                  read 10 articles that all seem to contradict each other. That’s why we created a way for you to get
                  your questions answered by our experienced Community Support team, which includes registered
                  dietitians and certified health coaches. This kind of help can feel like a lifesaver.
                </p>
                <p>Our team is here to offer you exceptional support!</p>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL
          id="whole-more-5"
          color="white"
          imgUrl="https://cdn.foodrevolution.org/wlc/upsell/img-community.jpg"
          imgPosition="left"
          type="triangle"
        >
          <h2 className="section-heading text-uppercase">Community (Uplifting Support)</h2>
          <p>No naysayers here! We know it can be tough to stick to goals if you’re on your own.</p>
          <p>
            In a world where the status quo revolves around toxic food and encourages us to put up with feeling worse
            each year, we need to surround ourselves with others who share our values of healthy food and making a
            better planet for future generations.
          </p>
          <p>
            In this warm and welcoming group, you’ll find a space to ask questions and cheer each other on as we
            celebrate wins — and we do celebrate often. You simply have to experience for yourself what a difference
            this kind of support will make!
          </p>
        </SectionCL>

        <SectionCL
          id="whole-more-6"
          color="light-gray"
          imgPosition="right"
          imgUrl="https://cdn.foodrevolution.org/wlc/upsell/606196416.jpg"
          type="triangle"
        >
          <h2 className="section-heading text-uppercase">
            <span className="text-nowrap">Cutting-Edge</span> Science
          </h2>
          <p>
            We are fortunate to live in the most scientifically advanced period in the history of the world. However,
            information overload can make it hard to find accurate, credible sources for the topics you care about.
          </p>
          <p>
            Getting the right information makes all the difference when it comes to topics like cancer, autoimmune
            disorders, heart disease, thyroid health, weight and metabolism, pain, Alzheimer’s, and more.
          </p>
          <p>
            New discoveries are made daily — and you can count on us to keep you up to date with breaking news from
            credible sources.
          </p>
        </SectionCL>

        <SectionCL
          id="whole-theme"
          color="white"
          imgUrl="https://cdn.foodrevolution.org/wlc/upsell/couple-happy-bowls-square.jpg"
          imgPosition="left"
          type="triangle"
        >
          <h2 className="section-heading text-uppercase">What You’ll Get</h2>
          <IconListCL>
            <>6 delicious, whole foods, plant-powered recipes per week.</>
            <>Weekly videos from Ocean & friends, delivered to your inbox.</>
            <>Monthly Action Hour events with health revolutionaries.</>
            <>Action Checklists to keep you on track.</>
            <>1,250+ fabulous recipes.</>
            <>A private, moderated community.</>
            <>Live Q&A and In the Kitchen calls every month.</>
            <>Access to Health Coaches & Dietitians.</>
            <>Access to the entire library of 150+ videos.</>
            <>500+ health articles.</>
            <>Annual rates that will never go up as long as you remain a member.</>
            <>A state-of-the-art membership site that’s easy to navigate and much more!</>
          </IconListCL>
        </SectionCL>

        <SectionCL id="whole-why" color="light-gray" type="triangle">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col>
                <h2 className="section-heading text-center mb-5">Why Join WHOLE Life Club?</h2>
                <ImageCardCL
                  image="https://cdn.foodrevolution.org/wlc/upsell/why-money.jpg"
                  imageAlt="healthy food next to piggy bank with money"
                >
                  <h4 className="section-heading">Save money.</h4>
                  <p>
                    With our tips and recipes, you could easily save thousands of dollars a year — and with fewer trips
                    to the doctor and pharmacy aisle, you could save money and your life.
                  </p>
                </ImageCardCL>
                <ImageCardCL
                  image="https://cdn.foodrevolution.org/wlc/upsell/why-time.jpg"
                  imageAlt="clock on plate surrounded by healthy food"
                >
                  <h4 className="section-heading">Save time.</h4>
                  <p>
                    Learn proven food prep techniques to save time while cooking. Dramatically slash your time at the
                    grocery store.
                  </p>
                  <p>
                    Plus, all recipes can be adapted for gluten-, sugar-, oil-, and/or salt-free diets — so you won’t
                    waste time hunting for meals you can eat.
                  </p>
                </ImageCardCL>
                <ImageCardCL
                  image="https://cdn.foodrevolution.org/wlc/upsell/why-health.jpg"
                  imageAlt="stethoscope with healthy food"
                >
                  <h4 className="section-heading">Save your health.</h4>
                  <p>
                    Don’t miss out on critical health topics that every single person needs to know. You’ll also be
                    introduced to new topics on the cutting edge of science.
                  </p>
                  <p>
                    WHOLE Life Club is like a total health encyclopedia, giving you the resources you need when you need
                    them.
                  </p>
                </ImageCardCL>
                <ImageCardCL
                  image="https://cdn.foodrevolution.org/wlc/upsell/1371054097.jpg"
                  imageAlt="selective focus shot of young woman standing in the kitchen, enjoying a cup of coffee and reading a recipe off of digital tablet to a young man who is preparing food."
                >
                  <h4 className="section-heading">Save yourself hassle.</h4>
                  <p>
                    Whether you’re a seasoned chef or a beginner, you’ll get tips and tricks to help make cooking and
                    menu planning fun.
                  </p>
                  <p>
                    Healthy eating is the ultimate way to give your body the nutrition it needs so you can keep doing
                    what you love.
                  </p>
                </ImageCardCL>
                <ImageCardCL
                  image="https://cdn.foodrevolution.org/wlc/upsell/why-planet.jpg"
                  imageAlt="globe sitting on plate surrounded by healthy food"
                >
                  <h4 className="section-heading">Save the planet.</h4>
                  <p className="balance-text">
                    Putting more veggies on your plate is one of the best things you can do to save soil and water,
                    reduce pesticides, fight climate change, and preserve the beautiful Earth we call home.
                  </p>
                  <p>You’ll be eating more deliciously than ever while being a part of the solution!</p>
                </ImageCardCL>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SectionCL id="section-wlc-offer-2" color="purple" angle="none" order="last" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading text-white text-center text-uppercase">
                  {((product) => {
                    switch (product) {
                      case 'eft':
                        return (
                          <>
                            One-Time Offer for <i>Eating For Tomorrow</i> Impact Kit Owners Only
                          </>
                        );
                      case 'ff':
                        return (
                          <>
                            One-Time Offer for <i>Fantastic Fungi Impact Kit</i> Owners Only
                          </>
                        );
                      default:
                        return <>One-Time Offer</>;
                    }
                  })(product)}
                </h2>
                <h3 className="mt-0 text-white text-center text-uppercase mb-5">Try WHOLE Life Club For Free Today</h3>
                <CtaSection successUrl={successUrl} declineUrl={declineUrl} />
              </Col>
            </Row>
          </Container>
        </SectionCL>
      </main>
      <div className="footer-content mt-auto">
        <Footer />
      </div>
    </div>
  );
}

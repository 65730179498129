import React, {useCallback, useState} from 'react';
import {Button, Col, Row} from 'reactstrap';

import Modal from 'components/Modal';
import useExitIntent from 'utils/useExitIntent.js';

export default function ModalExitLanding({entry, ...props}) {
  const {isOpen, toggle} = useExitIntent(30000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-landing" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h4 className="section-heading mb-4">Wait a second…</h4>
          <p className="mb-0">
            {
              {
                prevent: (
                  <>
                    You’re too busy to learn the <br className="d-none d-lg-block d-xl-none" />5 essentials{' '}
                    <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    for adding up to <br className="d-none d-lg-block d-xl-none" />
                    15 years to your life?
                  </>
                ),
                reverse: (
                  <>
                    Is there anything more important than <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    learning the 5 essential steps to feel <br className="d-none d-sm-block d-lg-none d-xl-block" />
                    good again and extend your lifespan?
                  </>
                )
              }[entry]
            }
          </p>
          <Row className="d-flex align-items-center mt-5">
            <Col xs="12" lg="6" className="mb-4 mb-lg-0 pr-lg-0">
              <Button
                className="text-uppercase text-white btn-exit-orange btn-exit-ul-landing"
                color="cta"
                onClick={toggle}
                block
              >
                Watch <br className="d-none d-lg-block d-xl-none" />
                the <br className="d-none d-xl-block" />
                Mini-Masterclass
              </Button>
            </Col>
            <Col xs="12" lg="6">
              <Button className="text-uppercase text-800 text-white" color="exit-gray" onClick={toggle} block>
                No thanks, I already know this info
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="5" className="exit-modal-image-col order-lg-1">
          <div
            className="exit-modal-image"
            style={
              {
                prevent: {backgroundImage: `url('${require('static/backgrounds/1159091878-exit.jpg')}')`},
                reverse: {backgroundImage: `url('${require('static/backgrounds/1298392392-exit.jpg')}')`}
              }[entry]
            }
          />
        </Col>
      </Row>
    </Modal>
  );
}

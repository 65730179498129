import React, {Fragment, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCartPlus} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import useBalanceText from 'utils/useBalanceText';

import Section, {SectionImage} from 'components/Elements/Section';
import CheckoutButton from 'components/CheckoutButton';
import ProductImage from 'components/Elements/ProductImage';
import {SalesDisclaimer} from 'components/SalesCta';
import CountdownTimer from 'components/Elements/CountdownTimer';
import GuaranteeBox from 'components/Elements/Guarantee';
import IconList, {IconListItem} from 'components/Elements/IconList';
import {ImgCardCol} from 'components/Elements/ImgBullet';
import FaqList from 'components/FaqList';

import {courseMaterial, lessons} from 'data/ul-info.json';
import {bonusSession} from 'data/bonus.json';
import {faq} from 'data/faq.json';
import SectionCL from 'components/ComponentLibrary/SectionCL';
import ImageCardCL from 'components/ComponentLibrary/ImageCardCL';
import IconListCL from 'components/ComponentLibrary/IconListCL/IconListCL';

export default function SalesCopy({
  version = 'sales',
  variant = null,
  bucketOverride = null,
  productId,
  productSku,
  productSlug,
  productPrice,
  paymentPlanPrice,
  salesFullPrice = false,
  ctaOverride = null,
  timerOverride = null,
  ctaNoTimer = false,
  ...props
}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      sales: salesSchedule = {
        phase: 'during'
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();
  const {bucket: userBucket = []} = useUserState();
  const {bucket: webinarBucket} = webinarSchedule;
  const segment = bucketOverride ?? webinarBucket ?? userBucket ?? 'prevent';

  const salesEnd = new Date(webinarSchedule.endOfSalesDay * 1000).getTime();
  const salesTimerDate = timerOverride ? timerOverride : salesEnd;

  function SectionSales({
    id,
    sectionHeading = undefined,
    angle = 'bottom',
    className = undefined,
    order = undefined,
    ...props
  }) {
    return (
      <SectionCL id={id} color="purple" angle={angle} className={className} order={order} type="triangle">
        <Container>
          <Row>
            <Col>
              {sectionHeading ? <div className="text-center text-white mb-5">{sectionHeading}</div> : null}
              <div className="background-white rounded text-center p-4 p-lg-5">
                {version === 'screening' ? (
                  <>
                    <h2 className="section-heading mb-2">Today only</h2>
                    <h3 className="mt-0">
                      Enroll in <i>Unlocking Longevity: The Keys to a Long and Healthy Life</i> with John Robbins, for
                      66% off
                    </h3>
                  </>
                ) : (
                  <h3 className="section-heading">
                    {!salesFullPrice ? (
                      <>Enroll in Unlocking Longevity for 50% off</>
                    ) : (
                      <>Enroll in Unlocking Longevity</>
                    )}
                  </h3>
                )}
                <p>
                  <ProductImage maxHeight="500" />
                </p>
                {!salesFullPrice ? (
                  <>
                    <h4 className="mt-4 mb-3">
                      Normally <del>$197</del>
                    </h4>
                    <h2 className="text-green m-0">
                      {version === 'screening' ? <>Masterclass Special: $67</> : <>Now Only: $97</>}
                    </h2>
                  </>
                ) : (
                  <h2 className="text-green m-0">Your Price: $197</h2>
                )}
                {
                  {
                    screening: null,
                    sales: (
                      <>
                        {!salesFullPrice && (
                          <>
                            {ctaNoTimer ? null : (
                              <>
                                <div className="cta-timer mt-4 mb-3">
                                  <div className="offer-box-timer-text">Your discount expires in:</div>
                                  <CountdownTimer time={salesTimerDate} className="countdown-dark" />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )
                  }[version]
                }
                <CheckoutButton
                  sku={productSku}
                  price={productPrice}
                  slug={productSlug}
                  className="offer-box-button my-5"
                >
                  <FontAwesomeIcon className="mr-2" icon={faCartPlus} /> Buy Now
                </CheckoutButton>
                <p>
                  <b>Get instant access — even at 2 am – guaranteed!</b>
                </p>
                <SalesDisclaimer />
              </div>
            </Col>
          </Row>
        </Container>
      </SectionCL>
    );
  }

  useBalanceText();
  return (
    <>
      {version === 'screening' ? (
        <SectionSales
          id="offer-cta-1"
          sectionHeading={
            <>
              <h3 className="section-heading mb-2">One-time offer</h3>
              <p className="mb-1">Special discount is only available on this page.</p>
              <p className="mb-0">
                <i>Please do not close or refresh this page.</i>
              </p>
            </>
          }
        />
      ) : null}

      <SectionCL
        id="offer-ditch-stereotype"
        color={version === 'screening' ? 'black' : 'purple'}
        className="text-center text-white"
        type="triangle"
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="10">
              <h2 className="section-heading">Ditch the Stereotype of Aging Miserably.</h2>
              <h3 className="mt-0">
                {
                  {
                    prevent: (
                      <>
                        Discover the Health Secrets to Living Longer & Living Better — Based On What The World’s Longest
                        Living People Do
                      </>
                    ),
                    reverse: <>You Deserve To Feel Better and To Love Your Life</>
                  }[segment]
                }
              </h3>
              <p className="balance-text">
                {
                  {
                    prevent: (
                      <>
                        What if you could defy the status quo that says aging is a miserable experience? What if
                        something MUCH better is possible?
                      </>
                    ),
                    reverse: (
                      <>
                        What if you could undo the so-called “inevitable” signs of aging like disease, brain fog, and
                        chronic pain — and feel better than you have in years?
                      </>
                    )
                  }[segment]
                }
              </p>
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL id="offer-intro" className="text-center" color="white" type="triangle">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10">
              <h2 className="section-heading mb-3">Introducing Unlocking Longevity</h2>
              <h4 className="text-400 mt-0">
                <i>The Keys to a Long and Healthy Life</i>
              </h4>
              <ProductImage />
              {
                {
                  prevent: (
                    <>
                      <h5>
                        When you think of your future, do you imagine a life where you are more or less bedridden and
                        saddled with diseases that zap the joy out of life? Do you fear that dementia and memory loss
                        are inevitable and that there’s a good chance you’re headed for years of drawn-out suffering due
                        to one or more debilitating diseases?
                      </h5>
                      <p className="balance-text">
                        <b>When did we accept that this is just the way life is when we get older?</b>
                      </p>
                      <p className="balance-text">
                        Too tired to spend time with loved ones. No ability to do favorite hobbies anymore.
                      </p>
                      <p className="balance-text">
                        Or confined to a nursing home, with the only excitement being a daily snack and once-a-week
                        Bingo.
                      </p>
                    </>
                  ),
                  reverse: (
                    <>
                      <h5>
                        Who needs another decade of feeling worse? It’s time to get the straightforward steps — so you
                        can do what you want, when you want. And so you can love every precious day of your life.
                      </h5>
                      <p>
                        <b>When did we accept that this is just the way life is when we get older?</b>
                      </p>
                      <p>Feeling the effects of age. Slowing down. Senior moments. A scary diagnosis.</p>
                      <p>
                        Is this how life is going to be from now on? Creaking along, living in fear that it’s only going
                        to get worse…
                      </p>
                      <p>Isn’t there something you can do to get your life back?</p>
                      <p>
                        To prevent a future where you are bedridden and where dementia and memory loss seem inevitable?
                        Too tired to spend time with loved ones. No ability to do favorite hobbies anymore. Or confined
                        to a nursing home, with the only excitement being a daily snack and once-a-week Bingo.
                      </p>
                      <p>
                        The worst of it is when your medical team doesn’t offer much help besides handing out more
                        prescriptions. But you know there are alternatives, and you’re determined to find them.
                      </p>
                    </>
                  )
                }[segment]
              }
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL
        id="offer-different"
        color="light-gray"
        imgPosition="left"
        imgUrl={require('static/backgrounds/1312102723.jpg')}
        type="triangle"
      >
        {
          {
            prevent: (
              <>
                <h3 className="section-heading">
                  What if your life could be <i>different?</i>
                </h3>
                <p>
                  Imagine a different kind of future. One where your wisdom is respected, and you’re able to keep doing
                  the things you love and even take up new hobbies. Where you dodged the all-too-common diseases that
                  you’ve seen elders in your own family struggle with.
                </p>
                <p>
                  <b>Picture this:</b> You’re in your eighties and you wake up easily after another night of great
                  sleep. You pour yourself a cup of tea and flip through a travel book for the vacation you’ll be taking
                  in a few weeks, making notes of the places you want to visit.
                </p>
              </>
            ),
            reverse: (
              <>
                <h3 className="section-heading">
                  What if your life was <i>different?</i>
                </h3>
                <p>
                  Imagine a different kind of future. One where your wisdom is respected, and you’re able to keep doing
                  the things you love and even take up new hobbies. Where you dodge the all-too-common diseases that
                  you’ve seen elders in your own family struggle with. Where you pleasantly surprise your doctors
                  because your lab work keeps getting better and better.
                </p>
                <p>
                  <b>Picture this:</b> You’re in your nineties, and you wake up easily after another night of great
                  sleep. You pour yourself a cup of tea and flip through a travel book for the vacation you’ll be taking
                  in a few weeks, making notes of the places you want to visit.
                </p>
              </>
            )
          }[segment]
        }
        <p>
          When you’re done with your tea, you enjoy a healthy breakfast that will give you energy for the day, then you
          head outside for a walk in nature with a friend. You laugh easily and often and marvel at the new growth on
          the trees.
        </p>
        <p>
          In the afternoon, you’ll have a painting class, and while you’re there you get a message. What does it say?
          It’s a loved one inviting you over for dinner that night. You smile and accept the invitation, offering to
          bring a black bean salad. (It’s everyone’s favorite.)
        </p>
        <p>
          Once upon a time, you would have not had the energy to do so much in a day. But today, you know exactly what
          to eat and do to get much more out of life — and you’re doing it!
        </p>
      </SectionCL>

      <SectionCL
        id="offer-fairytale"
        color="white"
        imgPosition="right"
        imgUrl={require('static/backgrounds/1435198936.jpg')}
        type="triangle"
      >
        <h5 className="section-heading">
          This future life isn’t a fairytale. People all around the world are thriving well into their 80s, 90s, and
          even 100s.
        </h5>
        <p>
          While most people today are stressed about how life will be in the coming decades, there’s a growing number of
          people who have decided to do things differently.
        </p>
        <p>
          There are people who are successfully bucking the mainstream narrative that getting older is something to be
          feared.
        </p>
        <p>
          {
            {
              prevent: (
                <>They’re out there beating the disease statistics, staying active, and living life to the fullest.</>
              ),
              reverse: (
                <>They’re out there reversing disease symptoms, getting more active, and living life to the fullest.</>
              )
            }[segment]
          }
        </p>
      </SectionCL>

      <SectionCL
        id="offer-how"
        color="light-gray"
        imgPosition="left"
        imgUrl={require('static/backgrounds/1342042952.jpg')}
        type="triangle"
      >
        <h4 className="section-heading">How do they do it?</h4>
        <p>
          The answer <i>isn’t</i> found in some $90 supplement that you have to buy every month.
        </p>
        <p>It’s not some rare superfood that you can only find on the internet.</p>
        <p>And it doesn’t require an extreme workout regimen that no one has time for.</p>
        <p>
          We’re being shown that something wonderful is possible — a far brighter future in which people remain active,
          productive, and resourceful until the very end. And we’re being shown the practical steps we can take to
          achieve it.
        </p>
      </SectionCL>

      {segment === 'reverse' && (
        <SectionCL
          id="offer-start"
          className="text-white"
          color="black"
          imgPosition="right"
          imgUrl={require('static/backgrounds/1329429481.jpg')}
          type="triangle"
        >
          <h3 className="section-heading">You don’t have to do it perfectly. You just need to start.</h3>
          <p>
            In Unlocking Longevity: The Keys to a Long and Healthy Life, we’ve outlined the simple steps that can help
            you to reverse disease and feel better than you have in years. You can start with small changes, like adding
            in one of the foods for longevity, or going on a short walk.
          </p>
          <p>
            We spell it out so that it’s all very doable. There’s no steep learning curve or complex concepts, just
            straightforward lessons based on the most current scientific research.
          </p>
        </SectionCL>
      )}

      <SectionCL id="offer-step-into" color="white" type="triangle">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading text-center mb-5">You Can Step Into: </h2>
              <ImageCardCL image={require('static/backgrounds/1302577398-wide.jpg')} imageAlt="">
                <h4 className="section-heading">More Energy</h4>
                <p className="mb-0">
                  Many elders who subsisted on the wrong foods throughout their lives find themselves coming to a
                  grinding halt once they hit a certain age. But the world’s healthiest people continue to enjoy great
                  food — and an even better quality of life.
                </p>
              </ImageCardCL>
              <ImageCardCL image={require('static/backgrounds/1343203373-wide.jpg')} imageAlt="">
                <h4 className="section-heading">More Joy</h4>
                <p className="mb-0">
                  Forget the cliché of a grumpy senior. With the right food and lifestyle in place, you can create a
                  life that’s worth living as you enjoy doing the things you love for many years to come. And being a
                  radiant example of what’s possible, for everyone in your family.
                </p>
              </ImageCardCL>
              <ImageCardCL image={require('static/backgrounds/1368875089-wide.jpg')} imageAlt="">
                <h4 className="section-heading">Low Rates of Disease</h4>
                <p className="mb-0">
                  Many of the common afflictions that set in later in life are totally preventable when you know what to
                  do. But outsmarting disease doesn’t happen by accident, so it’s critical to get the facts now.
                </p>
              </ImageCardCL>
              <ImageCardCL image={require('static/backgrounds/1191395081-wide.jpg')} imageAlt="">
                <h4 className="section-heading">Knowing What To Eat</h4>
                <p className="mb-0">
                  Staying healthy isn’t just a matter of avoiding foods that are bad for us… it’s crucial to add in the
                  right foods so you don’t end up nutrient-deficient and slowly drained of your health.
                </p>
              </ImageCardCL>
              <ImageCardCL image={require('static/backgrounds/1308972968-wide.jpg')} imageAlt="">
                <h4 className="section-heading">Supplementing Smartly</h4>
                <p className="mb-0">
                  As we age, our body’s needs change. We stop being able to absorb certain nutrients, and that can lead
                  to problems down the road. Make sure you aren’t missing the critical supplements so you can stay
                  active.
                </p>
              </ImageCardCL>
              <ImageCardCL image={require('static/backgrounds/805085582-wide.jpg')} imageAlt="">
                <h4 className="section-heading">Exercising in Ways That Keep You Young</h4>
                <p className="mb-0">
                  We’ve all heard that exercise is good for us. The good news is you don’t need to run ultramarathons to
                  reap the rewards — you’ll learn what’s best in Unlocking Longevity.
                </p>
              </ImageCardCL>
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL
        id="offer-teacher-1"
        color="light-gray"
        imgPosition="right"
        imgUrl={require('static/speaker/john-robbins.jpg')}
        angle="none"
        innerClass="pb-0"
        type="triangle"
      >
        <h3 className="section-heading text-uppercase">
          Who Teaches <i>Unlocking Longevity: The Keys to a Long and Healthy Life?</i>
        </h3>
        <p>
          Unlocking Longevity is created and led by John Robbins, an award-winning activist whose internationally
          best-selling books have sold over two million copies. His widespread media attention includes being a featured
          expert on TV shows like <i>Oprah,</i> having his own PBS special about his life and mission, and cover stories
          and features in newspapers including <i>The New York Times</i> and <i>The Washington Post.</i>
        </p>
        <p>
          Considered by many to be one of the most eloquent and powerful spokespersons in the world for a sane, ethical,
          and sustainable future, John received a standing ovation when he spoke at the United Nations.
        </p>
      </SectionCL>

      <SectionCL id="offer-teacher-2" color="light-gray" innerClass="pt-0" type="triangle">
        <Container>
          <Row>
            <Col>
              <p>
                John had an unusual upbringing. As the only son of the co-founder of the Baskin-Robbins 31 Flavors ice
                cream empire, he grew up eating a ridiculous amount of ice cream — sometimes even eating ice cream for
                breakfast.
              </p>
              <p>
                From his earliest childhood, John was groomed by his father to one day take over and eventually inherit
                Baskin-Robbins. But in his early 20s, after his Uncle Burt Baskin died of a heart attack at the age of
                54, he chose to walk away from the company, and from any access to or dependence on his family’s ice
                cream fortune. He didn’t want to make his living selling a product that contributed to heart disease,
                Type 2 diabetes, obesity, and many of the other worst health afflictions of our times. Instead, he
                wanted to forge a new path — one where he could help people realize the power of diet and lifestyle, so
                they could achieve their dreams and enjoy a long, healthy life.
              </p>
              <p>
                This was all very personal for John. At the age of five, he had polio, which left him paralyzed from the
                waist down. He eventually regained use of his legs, and after changing his diet and lifestyle in a far
                healthier direction, became a marathon runner, mountain climber, and triathlete. Now in his late 70s, he
                lives with Post-Polio Syndrome (PPS) and faces it, like all the challenges in his life, striving to make
                the best of what he’s got to work with. To him, the purpose of a healthy diet and lifestyle isn’t to
                guarantee that you will never suffer or die. It’s to reduce suffering and to increase your capacity for
                vitality, joy, and contribution. John is widely considered one of the leading architects and initiators
                of the modern “health food” movement. He and his son and colleague, Ocean Robbins, founded the million+
                member Food Revolution Network to promote healthy, ethical, and sustainable food for all.
              </p>
              <p>
                In Unlocking Longevity, he’s distilled the biggest game changers to reverse and prevent disease, get
                better with age, and add many health-filled years to your life.
              </p>
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL id="offer-material" color="white" type="triangle">
        <Container>
          <Row>
            <Col>
              <h3 className="section-heading text-uppercase text-center">
                How Does <i>Unlocking Longevity: The Keys to a Long and Healthy Life</i> WORK?
              </h3>
              <p className="text-center balance-text mb-5">
                Unlocking Longevity’s course material is immediately available online in your private members-only
                portal and includes:
              </p>
            </Col>
          </Row>
          <Row className="d-flex align-items-stretch justify-content-center">
            {courseMaterial.map((courseItem, i) => (
              <Col lg="6" className="d-flex flex-grow mb-4" key={`courseItem` + i}>
                <div className="box-shadow-black-50 rounded">
                  <div className="text-center border-bottom border-color-black-25">
                    <img className="course-material-image rounded-top" src={courseItem.image} />
                  </div>
                  <div className="p-4 rounded-bottom">
                    <h5 className="section-heading text-center">{courseItem.title}</h5>
                    <ReactMarkdown
                      className="course-item-description"
                      source={courseItem.description || 'recipe description'}
                    />
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </SectionCL>

      <SectionCL id="offer-lessons" color="light-gray" type="triangle">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading text-center">The Unlocking Longevity Lessons</h2>
            </Col>
          </Row>
          {lessons.map((lessonItem, i) => (
            <Row className="course-lesson-item align-items-center py-4" key={`lessonItem` + i}>
              <Col lg="3" className="mb-4 mb-lg-0">
                <img src={lessonItem.image} />
              </Col>
              <Col lg="9">
                <h5 className="m-0">{lessonItem.lesson}</h5>
              </Col>
            </Row>
          ))}
        </Container>
      </SectionCL>

      <SectionCL id="offer-bonuses" color="white" type="triangle">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading text-uppercase text-center">Bonuses!</h2>
              <p className="balance-text mb-5">
                We’ve brought in four health and nutrition experts to help you navigate your own path to unlocked
                longevity. These interviews are here to help you pursue a long and purpose-driven life through the power
                of food and lifestyle choices. You’ll learn the biggest dos and don’ts of nutrition that will open your
                eyes and save you from going down the wrong path — and you’ll get audio files as well as PDF transcripts
                to download so you can revisit the content whenever you’d like. Get ready to build the life and the
                health of your dreams.
              </p>
              {bonusSession.map((bonusItem, i) => (
                <Fragment key={`bonusItem` + i}>
                  <Row className="align-items-center justify-content-center">
                    <Col lg="3" className="mb-4 mb-lg-0">
                      <img className="rounded-circle box-shadow-black-25" src={bonusItem.image} />
                    </Col>
                    <Col lg="9">
                      <p className="text-uppercase mb-2">
                        <b>Bonus Session #{i + 1}</b>
                      </p>
                      <h4 className="mt-0 mb-2">{bonusItem.speaker}</h4>
                      <h5 className="mt-0 mb-3">{bonusItem.title}</h5>
                      <ReactMarkdown source={bonusItem.description} />
                    </Col>
                  </Row>
                  {i < 3 ? <hr className="border-color-black-25 my-5" /> : null}
                </Fragment>
              ))}
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL
        id="offer-past-choices"
        color="light-gray"
        imgPosition="right"
        imgUrl={require('static/backgrounds/804802532.jpg')}
        type="triangle"
      >
        <h3 className="section-heading">Why You Aren’t a Victim of Your Past Choices</h3>
        <p>
          Most of us didn’t grow up with a blueprint for how to live a long, healthy life. In fact, we’re shown just the
          opposite, with a billion-dollar processed food industry that’s engineered to make us crave food that’s loaded
          with sugar, salt, and fat… food that’s been scientifically shown to fuel cancer, heart disease, Type 2
          diabetes, obesity, Alzheimer’s, and more.
        </p>
        <p>
          But the beautiful thing is that no matter where you’re starting — even if you haven’t always done the
          healthiest things — <b>you can make new choices now.</b>
        </p>
        <p>It’s not too late to make a difference. It’s not too late to change your fate.</p>
        <p>
          Your cells are constantly regenerating, which means that when you give your body the right ingredients, you
          can start fresh. Starting today.
        </p>
      </SectionCL>

      <SectionCL
        id="offer-months"
        color="white"
        imgPosition="left"
        imgUrl={require('static/backgrounds/1350164916.jpg')}
        type="triangle"
      >
        <h3 className="section-heading">Where Will You Be 6 Months From Now?</h3>
        <p>
          Aging doesn’t magically start once you hit a certain age. How we feel is the cumulation of small daily choices
          that add up over time.
        </p>
        <p>
          The sooner you take action, the sooner you’ll be able to reap the rewards and avoid many of the standard
          gripes and diseases that so commonly rob people of their precious lives.
        </p>
        <p>
          <b>
            Right now, you can make a decision to evade the status quo and live differently than the average person.
          </b>
        </p>
        <p>
          <b>Stop buying into the myth that aging harshly is inevitable.</b>
        </p>
        <p>
          <b>And stop trying to figure it out on your own.</b>
        </p>
        <p>
          There’s no need to deny yourself access to expert advice, especially with something as important as your
          future quality of life.
        </p>
        <p>We all need someone who can show us the scientifically proven roadmap for how it’s done.</p>
      </SectionCL>

      <SectionCL
        id="offer-learn-1"
        color="light-gray"
        imgPosition="right"
        imgUrl={require('static/backgrounds/1359005512.jpg')}
        angleBottomDisplay="lg-none"
        type="triangle"
      >
        <h4 className="section-heading">Here’s a taste of what you’ll learn inside Unlocking Longevity:</h4>
        <IconListCL>
          <>How to outsmart cancer, heart disease, Type 2 diabetes, Alzheimer’s, and more</>
          <>What to eat to prevent broken bones that land many seniors in assisted living centers</>
          <>Saving money while eating better</>
          <>Simple tips to increase the bioavailability of nutrients in the food you eat</>
          <>How to prevent the loss of muscle mass and weakness as you age</>
        </IconListCL>
      </SectionCL>

      <SectionCL
        id="offer-learn-2"
        color="light-gray"
        imgPosition="left"
        imgUrl={require('static/backgrounds/638287652.jpg')}
        innerClass="sectionCL-padding-lg"
        type="triangle"
      >
        <IconListCL>
          <>
            The 5 basic healthy lifestyle practices that follow the example of the longest-lived and healthiest people
            in the world
          </>
          <>
            Regaining the vigor, vitality, muscular strength, and aerobic endurance you might have thought were gone
            forever
          </>
          <>The single biggest daily activity to promote better sleep</>
          <>The critical supplements that everyone over 50 years old should consider taking</>
          <>What type of exercise is best to keep all systems working at their peak</>
        </IconListCL>
      </SectionCL>

      <SectionCL
        id="offer-foryou-1"
        color="white"
        imgPosition="right"
        imgUrl={require('static/backgrounds/1341570412.jpg')}
        angleBottomDisplay="lg-none"
        type="triangle"
      >
        <h3 className="section-heading">
          Is <i>Unlocking Longevity: The Keys to a Long and Healthy Life,</i> Right For You?
        </h3>
        <p>
          Take a stroll through your local drugstore, and you’ll see dozens upon dozens of products that promise to make
          you look younger and healthier. But the truth is that health isn’t found in a bottle. It comes, more than
          anything else, from the choices you make and the actions you take. Simple steps in the right direction can
          transform the course of your life.
        </p>
        <p>
          While we believe that everyone in the world can benefit from the strategies and tools inside Unlocking
          Longevity, we know that some people are looking for a magic bullet. We want to be upfront — there is no magic
          bullet in this course.
        </p>
        <p>
          What you WILL find is guidance to help you create the necessary ingredients for lasting health — all based on
          reputable scientific research, conducted over many decades.
        </p>
        <p>You’ll learn the true essentials for living a long, happy, healthy life.</p>
      </SectionCL>

      <SectionCL
        id="offer-foryou-2"
        color="white"
        imgPosition="left"
        imgUrl={require('static/backgrounds/1213656551.jpg')}
        innerClass="sectionCL-padding-lg"
        type="triangle"
      >
        <p>
          We’ve put it all together for you so that you can just hit the “play” button and get the education you need to
          stay healthy for, hopefully, decades to come.
        </p>
        <p>Unlocking Longevity is for you if:</p>
        <IconListCL>
          <>You like to learn new things</>
          <>You’re open to making changes that will benefit you</>
          <>
            You want both inspiration <i>and</i> information
          </>
          <>You’re ready to get the facts and take action</>
          <>You’re looking for solid strategies that work, not fluff or unattainable promises</>
        </IconListCL>
        <p>
          If you’re looking for time-tested and scientifically-backed strategies to maximize your lifespan and your
          healthspan, and you’re ready to take action so you can get results in your life, then{' '}
          <i>Unlocking Longevity: The Keys to a Long and Healthy Life,</i> is for you.
        </p>
      </SectionCL>

      <SectionSales
        id="offer-cta-2"
        sectionHeading={
          <>
            <h3 className="section-heading mb-2">
              Ready Now? <i>Join Us!</i>
            </h3>
            <h5 className="mt-0">Unlimited Access. Enjoy it all any time — for life!</h5>
          </>
        }
      />

      {version === 'screening' && (
        <SectionCL id="offer-special-enrollment" color="white" className="text-center" type="triangle">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading">About Today’s Masterclass Special Enrollment Discount</h2>
                <p>This early-bird discount is only for Masterclass attendees.</p>
                <p>
                  *You can <i>only</i> unlock your special price on this page.*
                </p>
                <p className="balance-text">
                  <b>With this special Masterclass price, you save $130 by enrolling in Unlocking Longevity today.</b>
                </p>
                <p className="balance-text">
                  Order from this page to get the best offer possible. (Do not hit refresh or close the page!) The
                  enrollment price is higher everywhere else on our site.
                </p>
              </Col>
            </Row>
          </Container>
        </SectionCL>
      )}

      <SectionCL id="offer-guarantee" color="light-gray" type="triangle">
        <Container>
          <Row>
            <Col>
              <GuaranteeBox title={<>You’re Protected By Our Iron-Clad 60-Day, Unconditional, Money-Back Guarantee.</>}>
                <p>
                  Try it out. If you aren't completely blown away by the course, even if you just don’t like it, just
                  email us at <a href="mailto:support@foodrevolution.org">support@foodrevolution.org</a> any time within
                  60 days after purchase to ask for a prompt and courteous refund. This means you can take the whole
                  course, take every lesson, and make every single mouth-watering recipe, and if it doesn’t rock your
                  world, you get every penny back. That’s how confident we are that you’ll LOVE Unlocking Longevity!
                </p>
                <p>
                  PLUS, if for any reason you request a refund, the entire course and everything that you’ve downloaded
                  is still yours to keep. That’s why we call our guarantee “better than money back.”
                </p>
              </GuaranteeBox>
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL id="offer-faq" color="white" type="triangle">
        <Container>
          <Row>
            <Col>
              <h2 className="section-heading mb-5">Frequently Asked Questions</h2>
              <FaqList faqlist={faq} />
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionCL
        id="offer-change"
        color="light-gray"
        imgPosition="left"
        imgUrl={require('static/backgrounds/1304365314.jpg')}
        type="triangle"
      >
        <h2 className="section-heading">You’re a few clicks away from changing your life</h2>
        <p>
          We have an incredible opportunity in front of us. At no other time in history have we had such specific
          know-how for living a long, healthy life. Experts have meticulously studied what to do. You don’t have to do
          all this research yourself — tracking down the reports, the ins and outs, and the details. It’s all inside
          Unlocking Longevity.
        </p>
        <p>
          Our ancestors didn’t have access to this knowledge — the specific, repeatable steps that keep us sharp and
          active. As a result, many of them didn’t live as long, or as well as they could have.
        </p>
        <p>
          You get to do things <i>differently.</i>
        </p>
      </SectionCL>

      <SectionCL
        id="offer-roadmap"
        color="white"
        imgPosition="right"
        imgUrl={require('static/backgrounds/635759292.jpg')}
        type="triangle"
      >
        <h3 className="section-heading">You have in front of you a roadmap. Will you take it?</h3>
        <p>
          If not now, when? When you have more time? We all know that day may never come. Each meal that lacks the right
          nutrients, each day you choose the couch over a walk, and each time we pass up gratitude and connection, we
          lose something precious.
        </p>
        <p>
          Don’t wait for the magic day when you have more bandwidth. That’s how years fly by and “suddenly” your health
          isn’t what it used to be. Some people suddenly face a crisis — or worse. But we know it’s not all of a sudden.
          It’s a result of the decisions we made years ago.
        </p>
        <p>
          In one year, where will you be? Will you be headed towards health or illness? Will you be feeling better than
          you have in years, and confident that you’re doing what it takes to protect your future?
        </p>
        <h3 className="mt-0">The most important thing is that YOU get to decide. Before it’s too late.</h3>
      </SectionCL>

      <SectionCL id="offer-created" color="light-gray" type="triangle">
        <Container>
          <Row className="justify-content-center">
            <Col lg="10" xl="8">
              <div className="box-shadow-black-50 rounded">
                <div className="background-black rounded-top text-center text-white p-4">
                  <h4 className="m-0">
                    Join <i>Unlocking Longevity: The Keys to a Long and Healthy Life</i>
                  </h4>
                </div>
                <div className="background-white rounded-bottom p-4">
                  <IconList>
                    {courseMaterial.map((courseItem, i) => (
                      <IconListItem key={`courseItem` + i}>{courseItem.title}</IconListItem>
                    ))}
                  </IconList>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionCL>

      <SectionSales id="offer-cta-3" angle="none" order="last" />
    </>
  );
}

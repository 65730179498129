import React, {useEffect} from 'react';
import track from 'utils/track';
import SalesIndex from './join';

export default function () {
  useEffect(
    () =>
      track('Product Viewed', {
        product_id: 'SC-UL',
        sku: 'SC-UL',
        name: 'Unlocking Longevity',
        variant: 'ul-special',
        value: 97,
        currency: 'usd'
      }),
    []
  );
  return <SalesIndex ctaNoTimer ctaOverride="joinSales" />;
}

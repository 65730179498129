import React, {useCallback, useState, useEffect, useRef} from 'react';
import {Duration} from 'luxon';
import {Button, Progress, Container, Col, Row} from 'reactstrap';
import ReactPlayer from 'react-player';
import {faPlay, faPause} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {secondsToTime} from 'utils/time';
import './AudioPlayer.scss';

export default function AudioPlayer({url, events = [], ...props}) {
  const [firedEvents, setFiredEvents] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState({played: null, playedSeconds: null});
  const [duration, setDuration] = useState(0);
  const player = useRef(null);

  useEffect(() => {
    events &&
      events.length &&
      setFiredEvents(
        [].concat(
          firedEvents,
          events.filter(
            (e) =>
              !firedEvents.includes(e.name) &&
              !isNaN(e.pos) &&
              ((e.pos < 1 && e.pos < progress.played) || (e.pos >= 1 && e.pos < progress.playedSeconds))
          )
        )
      );
  }, [progress.played]);

  const togglePlaying = useCallback(() => {
    setPlaying((playing) => !playing);
  }, []);

  const _onProgress = useCallback(
    (e, ...args) => {
      const {onProgress = () => {}} = props;
      const {played, playedSeconds} = e;
      setProgress({played, playedSeconds});
      onProgress.apply(this, [e, args]);
    },
    [props.onProgress]
  );

  const handleProgressClick = useCallback((e) => {
    e.preventDefault();
    const {offsetX} = e.nativeEvent;
    const totalWidth = e.currentTarget.clientWidth;
    const percent = (1.0 * offsetX) / totalWidth;
    const newTime = percent;
    player.current.seekTo(newTime);
  }, []);

  return (
    <div className="audio-wrapper">
      <div className="play-control-wrap">
        <Button color="play-control" onClick={togglePlaying}>
          <FontAwesomeIcon icon={playing ? faPause : faPlay} />
        </Button>
      </div>
      <div className="audio-progress-wrap" onClick={handleProgressClick}>
        <Progress className="w-100 background-white-60" color="green" max={1} value={progress.played} />
      </div>
      <div className="audio-progress-value text-white text-small">
        {player.current ? `- ${secondsToTime(duration - progress.playedSeconds)}` : null}
      </div>
      <ReactPlayer
        className="audio-player"
        width="0"
        height="0"
        url={url}
        playing={playing}
        onProgress={_onProgress}
        ref={player}
        onDuration={setDuration}
      />
    </div>
  );
}

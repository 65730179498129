import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import MasterclassIndex from '../../masterclass/prevent';

export default function () {
  useEffect(() => {
    navigate('/a/masterclass/prevent/' + location.search);
  }, []);
  return <MasterclassIndex noFixedVideo noExitIntent variant="ad" entry="prevent" />;
}

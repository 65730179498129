import React from 'react';

export default function ProductImage({
  className = '',
  img = 'https://cdn.foodrevolution.org/ul/ul-product-image-v20221109.png',
  width = '1200',
  height = '717',
  altText = 'Unlocking Longevity product image',
  maxHeight = null,
  ...props
}) {
  let styles = {
    maxHeight: `${maxHeight}px`,
    width: 'auto'
  };
  return (
    <>
      <img
        className={className}
        src={img}
        width={width}
        height={height}
        alt={altText}
        style={maxHeight ? styles : null}
      />
    </>
  );
}

import React from 'react';
import MasterclassIndex from './prevent';

export default function () {
  return (
    <MasterclassIndex
      entry="reverse"
      pageTitle="Unlocking Longevity: How to Feel Better Than You Did 10 Years Ago"
      pageDescription="The plant-based keys to a long and fabulously healthy life."
      ogImage="https://cdn.foodrevolution.org/ul/og/ul-opengraph-Reversal-Oriented-1200x1200-v20221206.jpg"
      shareUrl="https://longevity.foodrevolution.org/masterclass/reverse/"
      facebookDescription="Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer."
      twitterDescription="Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer."
      twitterImg="https://cdn.foodrevolution.org/ul/og/ul-opengraph-Reversal-Oriented-1200x630-20221206.jpg"
      pinterestDescription="Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer."
      pinterestImage="https://cdn.foodrevolution.org/ul/og/ul-opengraph-Reversal-Oriented-1000x1500-20221206.jpg"
    />
  );
}

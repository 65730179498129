import React, {useCallback, useState} from 'react';
import {Button, Container, Col, Row, Collapse} from 'reactstrap';
import LazyLoad from 'react-lazyload';
import ReactMarkdown from 'react-markdown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown, faChevronCircleUp} from '@fortawesome/pro-light-svg-icons';
import classNames from 'classnames';
import {bonus} from 'data/bonus.json';
import './BonusList.scss';

function BonusItem({id, number, owner, value, title, description, image, ...props}) {
  return (
    <div className={classNames(`bonus bonus-border-${props.borderColor}`)}>
      <Row className="bonus-heading d-flex align-items-center justify-content-center">
        <Col xs="12" lg="10" className="order-lg-2 mb-3 mb-lg-0">
          <div className="bonus-title">{title}</div>
          <div className="bonus-from">from {owner}</div>
          <div className="bonus-value">- {value}</div>
        </Col>
        <Col xs="12" sm="8" lg="2" className="text-center order-lg-1">
          <LazyLoad height={180} offset={100}>
            <img className="bonus-img img-fluid mx-auto" src={image} />
          </LazyLoad>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactMarkdown className="bonus-description" source={description} />
        </Col>
      </Row>
    </div>
  );
}

BonusItem.defaultProps = {
  borderColor: 'light-gray-100'
};

export default function BonusList({id, ...props}) {
  return bonus.map((bonus) => <BonusItem {...bonus} key={bonus.id} />);
}

export function BonusAccordion({bonuslist = bonus, firstBonus = 0, borderColor = 'light-gray-100', ...props}) {
  const [activeCards, setActiveCards] = React.useState([]);
  const onClick = React.useCallback(
    (key) => () => setActiveCards((state) => (state.includes(key) ? state.filter((e) => e !== key) : [...state, key])),
    []
  );
  const sortedBonuses = firstBonus
    ? bonuslist.filter((e) => e.id === firstBonus).concat(bonus.filter((e) => e.id !== firstBonus))
    : bonuslist;
  return sortedBonuses.map((bonus, index) => {
    return (
      <div className={classNames(`bonus bonus-item bonus-border-${borderColor}`)} key={index}>
        <div className="module-item-header d-flex align-items-center justify-content-between" onClick={onClick(index)}>
          <div className="module-item-header-text flex-grow-1">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0">
                <img className="bonus-img" src={bonus.image} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="bonus-number">Free Bonus #{index + 1}</div>
                <div className="bonus-title">{bonus.title}</div>
                <div className="bonus-from">from {bonus.owner}</div>
                {bonus.value ? <div className="bonus-value">Value: {bonus.value}</div> : null}
              </Col>
            </Row>
          </div>
          <div className="module-item-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row">
            <FontAwesomeIcon
              className="module-item-icon mb-1 mb-lg-0 mr-lg-1"
              icon={activeCards.includes(index) ? faChevronCircleUp : faChevronCircleDown}
            />
            <div className="module-item-header-icon-text text-small text-uppercase">
              {activeCards.includes(index) ? (
                <>Close</>
              ) : (
                <>
                  <span className="d-none d-lg-inline">Learn</span> More
                </>
              )}
            </div>
          </div>
        </div>
        <Collapse isOpen={activeCards.includes(index)}>
          <div className="module-item-content">
            <ReactMarkdown className="bonus-description" source={bonus.description} />
          </div>
        </Collapse>
      </div>
    );
  });
}

export function BonusOpen({bonuslist = bonus, firstBonus = 0, borderColor = 'light-gray-100', ...props}) {
  const sortedBonuses = firstBonus
    ? bonuslist.filter((e) => e.id === firstBonus).concat(bonus.filter((e) => e.id !== firstBonus))
    : bonuslist;
  return sortedBonuses.map((bonus, index) => {
    return (
      <div className={classNames(`bonus bonus-item bonus-border-${borderColor}`)} key={index}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="module-item-header-text flex-grow-1">
            <Row className="bonus-heading d-flex align-items-center justify-content-start m-0">
              <Col xs="12" md="3" lg="2" className="text-center mb-3 mb-md-0">
                <img className="bonus-img" src={bonus.image} />
              </Col>
              <Col xs="12" md="9" lg="10">
                <div className="bonus-number">Free Bonus #{index + 1}</div>
                <div className="bonus-title">{bonus.title}</div>
                <div className="bonus-from">from {bonus.owner}</div>
                {bonus.value ? <div className="bonus-value">Value: {bonus.value}</div> : null}
              </Col>
            </Row>
          </div>
        </div>
        <div className="module-item-content">
          <ReactMarkdown className="bonus-description" source={bonus.description} />
        </div>
      </div>
    );
  });
}

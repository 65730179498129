import React, {useCallback, useEffect, useState} from 'react';
import {Col, Container, Row} from 'reactstrap';
import classNames from 'classnames';
import {Location} from '@reach/router';
import queryString from 'query-string';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown} from '@fortawesome/pro-regular-svg-icons';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import useBalanceText from 'utils/useBalanceText';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import SectionCL from 'components/ComponentLibrary/SectionCL';
import CountdownTimer from 'components/Elements/CountdownTimer';
import Video from 'components/Video';
import IconListCL from 'components/ComponentLibrary/IconListCL/IconListCL';
import OptinModal, {OptinModalTrigger} from 'components/Modals/OptinModal';
import ModalExitLanding from 'components/Modals/ModalExitLanding';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Elements/Footer';
import OptInForm from 'components/Forms/OptInForm';
import {TestimonialBubble} from 'components/Testimonial';

export default function Index(props) {
  return (
    <Location>
      {({location, navigate}) => <MasterClassIndex location={location} navigate={navigate} {...props} />}
    </Location>
  );
}
function MasterClassIndex({
  location = {},
  entry = 'prevent',
  noShare = false,
  noFixedVideo = false,
  noExitIntent = false,
  slug = 'masterclass',
  navigate,
  variant = null,
  pageTitle = 'Unlocking Longevity: Lessons From The World’s Healthiest Elders',
  pageDescription = 'The plant-based keys to a long and fabulously healthy life.',
  ogImage = 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Prevention_Optimization-Oriented-1200x1200-v20221110.jpg',
  shareUrl = 'https://longevity.foodrevolution.org/masterclass/prevent/',
  facebookDescription = 'Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer.',
  twitterDescription = 'Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer.',
  twitterImg = 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Prevention_Optimization-Oriented-1200x630-20221110.jpg',
  pinterestDescription = 'Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer.',
  pinterestImage = 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Prevention_Optimization-Oriented-1000x1500-20221110.jpg',
  ...props
}) {
  const qs = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  const navigateWatch = useCallback(() => navigate('/masterclass/watch/'), []);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const pathName = window.location.pathname;
      const pathRegex = new RegExp(`\/${slug}\/`, 'g');
      if (!pathName.match(pathRegex)) {
        navigate(`/${slug}/` + location.search);
      }
    }
  }, []);

  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before',
        launchEnd: ''
      }
    }
  } = usePhaseState();

  const launchEnd = new Date(defaultSchedule.launchEnd).getTime();

  function OptinButton({className = undefined}) {
    return <OptinModalTrigger className={className} btnText="Watch Now" />;
  }

  function LandingTimer({className = null, timerClass = null, ...props}) {
    return (
      <div className={classNames(`section-countdown countdown-${props.light ? 'light' : 'dark'}`, className)}>
        <h5 className="timer-heading mt-0 mb-2">{defaultSchedule.launchTitle} ends in:</h5>
        <CountdownTimer time={launchEnd} className={timerClass} />
      </div>
    );
  }

  const posterUrl = {
    prevent: 'https://cdn.foodrevolution.org/ul/images/612641156-lg.jpg',
    reverse: 'https://cdn.foodrevolution.org/ul/images/1472519505-lg.jpg'
  }[entry];

  //useBalanceText();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />
      <div className="page-content">
        <Header
          className="background-white"
          logoWhite
          style="light"
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />

        <SectionCL
          id="landing-header-poster"
          imgUrl={posterUrl}
          color="black"
          innerWrapClass="my-auto flex-grow-1"
          innerClass="text-white text-center"
          angle="none"
          order="first"
          type="triangle"
        />
        <SectionCL id="landing-header-opt" color="white" type="triangle" angle="both" className="text-center">
          <Container>
            <Row>
              <Col>
                <div className="optin-box background-white box-shadow-black-50 p-4 p-lg-5 rounded">
                  <p className="balance-text">
                    <i>FREE Mini-Masterclass Now Playing For A Limited Time</i>
                  </p>
                  <h2 className="mt-0 mb-3">
                    {
                      {
                        prevent: <>Learn From The World’s Healthiest Elders</>,
                        reverse: (
                          <>
                            How to Feel Better <br className="d-none d-xl-block" />
                            Than You Did 10 Years Ago
                          </>
                        )
                      }[entry]
                    }
                  </h2>
                  <h3 className="mt-0 mb-3">
                    {
                      {
                        prevent: (
                          <>
                            <i>Discover what the longest-living people in the world do to thrive at 90+</i> — with
                            dramatically reduced odds of cancer, heart disease, dementia, and other chronic diseases.
                          </>
                        ),
                        reverse: (
                          <>
                            <i>
                              Discover what the most significant medical studies tell us about slowing or even reversing
                              the aging process.
                            </i>
                          </>
                        )
                      }[entry]
                    }
                  </h3>
                  <h5 className="mt-0 text-400 mb-4">with bestselling author John Robbins</h5>
                  <OptinButton />
                  {variant != 'ad' && (
                    <>{defaultSchedule.phase === 'during' && <LandingTimer className="countdown-small mt-4" />}</>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL
          id="landing-header-discover"
          color="purple"
          type="triangle"
          angle="bottom"
          className="text-center text-white"
        >
          <Container>
            <Row>
              <Col>
                <h3 className="section-heading">Forget “miracle” diets and anti-aging fads.</h3>
                <h4 className="text-400 mt-0 mb-0">
                  In This <b>15-Minute Mini-Masterclass</b> You’ll Discover:
                </h4>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL id="landing-discover" color="white" type="triangle" angle="bottom" className="text-h5">
          <Container>
            <Row>
              <Col>
                {
                  {
                    prevent: (
                      <IconListCL iconColor="purple">
                        <>
                          <b className="text-purple">
                            5 healthy lifestyle habits that could add 8 or more healthy years to your life
                          </b>{' '}
                          — even if you haven’t always eaten “perfectly”
                        </>
                        <>
                          <b className="text-purple">
                            Where the world’s healthiest elders live — and what we can all learn from them
                          </b>{' '}
                          (it’s not rocket science — but it IS really important to your health and your future!)
                        </>
                        <>
                          <b className="text-purple">The crucial difference between “lifespan” and “healthspan”</b> —
                          and how you can live not just longer, but live BETTER
                        </>
                        <>
                          <b className="text-purple">Public enemy #1:</b> The single biggest type of food to minimize or
                          eliminate (find out if you’re eating these if you want to prevent premature aging)
                        </>
                        <>
                          <b className="text-purple">How to regain the vitality,</b> muscular strength, and even much of
                          the aerobic endurance you might have feared could be gone forever
                        </>
                        <>
                          <b className="text-purple">One food to eat 3 times a week</b> to reduce chronic inflammation,
                          promote a healthy brain and a healthy microbiome and dramatically slash your risk of dementia,
                          Type 2 diabetes, cardiovascular disease, and even obesity
                        </>
                      </IconListCL>
                    ),
                    reverse: (
                      <IconListCL iconColor="purple">
                        <>
                          <b className="text-purple">The crucial difference between “lifespan” and “healthspan”</b> —
                          and how you can live not just longer, but live BETTER
                        </>
                        <>
                          <b className="text-purple">5 healthy lifestyle habits that can undo signs of aging</b> and add
                          8 or more healthy years of life
                        </>
                        <>
                          <b className="text-purple">Public enemy #1:</b> The single biggest type of food to minimize or
                          eliminate (find out if you’re eating these if you want to prevent premature aging)
                        </>
                        <>
                          <b className="text-purple">How to regain the vitality,</b> muscular strength, and even much of
                          the aerobic endurance you might have feared could be gone forever
                        </>
                        <>
                          <b className="text-purple">One food to eat 3 times a week</b> to reduce chronic inflammation,
                          promote a healthy brain and a healthy microbiome and dramatically slash your risk of dementia,
                          Type 2 diabetes, cardiovascular disease, and even obesity
                        </>
                        <>What to eat and do for a 78% lower risk of developing any chronic disease</>
                      </IconListCL>
                    )
                  }[entry]
                }
                <h5 className="text-center mt-4">All in just 15 minutes!</h5>
                <div className="text-center">
                  <OptinButton />
                </div>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL id="landing-future" color="purple" className="text-center text-white" type="triangle">
          <Container>
            <Row>
              <Col>
                <h3 className="section-only-heading">
                  Join this free Mini-Masterclass to make sure <br className="d-none d-xl-block" />
                  you aren’t missing any of the essential keys <br className="d-none d-xl-block" />
                  to living a long, healthy, happy life!
                </h3>
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL
          id="landing-host"
          color="light-gray"
          imgPosition="right"
          imgUrl={require('static/speaker/john-robbins.jpg')}
          type="triangle"
          collapseHeader={
            <>
              <h5 className="text-400 m-0">Click Here to</h5>
              <h4 className="m-0">Learn More About John Robbins</h4>
            </>
          }
          collapseHeadlines={
            <>
              <h2 className="section-heading mb-2">Your Host, John Robbins</h2>
              <h4 className="m-0 text-400">
                A food revolution leader <br className="d-none d-xl-block" />
                and inspiration to millions.
              </h4>
            </>
          }
          collapseContent={
            <>
              <p>
                John Robbins is the author of <i>Diet For A New America,</i> <i>The Food Revolution,</i>{' '}
                <i>Healthy At 100,</i> and 7 other bestsellers. His widespread media attention includes being a featured
                expert on TV shows like <i>Oprah,</i> having his own PBS special about his life and mission, and cover
                stories and features in newspapers, including <i>The New York Times</i> and <i>The Washington Post.</i>
              </p>
              <p>
                Today, John is the President and co-founder of Food Revolution Network along with his son, Ocean.
                Together, they host the annual Food Revolution Summit Docuseries — bringing cutting-edge insights from
                the world’s top doctors, experts, and food revolutionaries on what it takes to live an extraordinary
                life.
              </p>
            </>
          }
        />
        <SectionCL id="" color="white" type="triangle" angle="bottom" className="text-center">
          <Container>
            <Row>
              <Col>
                <img
                  className="mb-4"
                  src="https://cdn.foodrevolution.org/global/frn-logo-2024-trademarked-color.svg"
                  alt="Food Revolution Network logo"
                  width={300}
                  height={126}
                  style={{maxHeight: '200px'}}
                />
                <p className="balance-text">
                  Food Revolution Network’s mission is to inspire and advocate for healthy,{' '}
                  <br className="d-none d-xl-block" />
                  ethical, and sustainable food for all through education about plant-powered foods.
                </p>
                <p className="balance-text">
                  Guided by John and Ocean Robbins, with more than a million members and with the collaboration of many
                  of the top food revolutionary leaders of our times, FRN aims to change the way the world eats.
                </p>
                <p className="balance-text">
                  As a certified B Corp, we commit to these values in every aspect of our work.
                </p>
                <img
                  src="https://cdn.foodrevolution.org/global/b-corp-logo-black.svg"
                  alt="B Corp Logo"
                  width={103}
                  height={150}
                  style={{maxHeight: '200px'}}
                />
              </Col>
            </Row>
          </Container>
        </SectionCL>
        <SectionCL
          id="landing-cta"
          className="text-center"
          color="light-gray"
          angle="none"
          order="last"
          type="triangle"
        >
          <Container>
            <Row>
              <Col>
                <div className="background-white box-shadow-black-50 p-4 p-lg-5 rounded">
                  <h3 className="section-heading text-purple">There’s a lot at stake.</h3>
                  <p>
                    In this 15-minute Mini-Masterclass, you’ll learn why some people grow{' '}
                    <br className="d-none d-xl-block" />
                    old in sickness and despair while others grow old with vitality and inner peace.
                  </p>
                  <p>
                    Even if you haven’t always taken very good care of yourself, it’s possible{' '}
                    <br className="d-none d-xl-block" />
                    to make choices today that will greatly enhance what your future looks like.
                  </p>
                  <p className="text-h5 text-800">But please don’t wait.</p>
                  <p>
                    When it comes to healthy aging, the sooner you discover what to do{' '}
                    <br className="d-none d-xl-block" />— and what not to do — the better your chances of living a long
                    life <br className="d-none d-xl-block" />
                    with energy to keep doing what you love for many years to come.
                  </p>

                  <OptinButton className="mt-4" />
                  {variant != 'ad' && (
                    <>{defaultSchedule.phase === 'during' && <LandingTimer className="mt-4" light />}</>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </SectionCL>
      </div>
      <div className="footer-content mt-auto">
        <Footer />
        {!noShare && (
          <ShareButtons
            shareUrl={shareUrl}
            shareImg={ogImage}
            facebookDescription={facebookDescription}
            twitterDescription={twitterDescription}
            twitterImg={twitterImg}
            pinterestDescription={pinterestDescription}
            pinterestImage={pinterestImage}
            floating
          />
        )}
      </div>

      <OptinModal
        formProps={{
          contactFormProps: {
            id: 'ulOptin',
            entry: entry,
            formHeader: (
              <>
                <h5 className="section-heading text-center">
                  This Mini-Masterclass is absolutely FREE and plays on demand.
                </h5>
                <p className="text-center">Enter your name and email below to sign up.</p>
              </>
            ),
            btnCopy: <>Get Instant Access</>
          }
        }}
      />
      {!noExitIntent && <ModalExitLanding entry={entry} />}
    </div>
  );
}

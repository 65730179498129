

  
// Template Map
export default {
  '/opt/atlassian/pipelines/agent/build/src/pages/404.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/404.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/join-prevent.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/join-prevent.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/join-reverse.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/join-reverse.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/prevent.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/prevent.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/reverse.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a-g/masterclass/reverse.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join-prevent.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join-prevent.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join-reverse.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join-reverse.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/prevent.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/prevent.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/reverse.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/a/masterclass/reverse.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/frs-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/frs-upsell.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-discount.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join-special.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/join.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/join.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/index.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/index.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/prevent.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/prevent.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/prevent/replay.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/prevent/replay.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/replay.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/reverse.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/reverse.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/reverse/replay.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/reverse/replay.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/masterclass/watch.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/masterclass/watch.jsx').default,
'/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx': require('/opt/atlassian/pipelines/agent/build/src/pages/wlc-upsell.jsx').default
}

export const notFoundTemplate = '/opt/atlassian/pipelines/agent/build/src/pages/404.jsx'


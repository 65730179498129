export const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60));
  let divisorMinutes = secs % (60 * 60);
  let minutes = String(Math.floor(divisorMinutes / 60));
  let divisorSeconds = divisorMinutes % 60;
  let seconds = String(Math.ceil(divisorSeconds));
  if (seconds.length < 2) {
    seconds = `0${seconds}`;
  }
  if (hours && minutes.length < 2) {
    minutes = `0${minutes}`;
  }
  return `${hours ? hours + ':' : ''}${minutes}:${seconds}`;
};

import React, {useEffect} from 'react';
import {usePhaseState} from 'funnel-schedule/phase-context';
import SalesIndex from './join';
import track from 'utils/track';

export default function (props) {
  const {
    phases: {
      sales: salesSchedule = {
        phase: 'during',
        salesEnd: ''
      }
    }
  } = usePhaseState();

  useEffect(() => {
    track('Product Viewed', {
      product_id: 'SC-UL',
      sku: 'SC-UL',
      name: 'Unlocking Longevity',
      variant: salesSchedule.phase != 'during' ? 'ul' : 'ul-special',
      value: salesSchedule.phase != 'during' ? 197 : 97,
      currency: 'usd'
    });
  }, [salesSchedule.phase]);

  const salesTimeOverride = new Date(salesSchedule.salesEnd).getTime();

  return (
    <SalesIndex
      ctaOverride={salesSchedule.phase != 'during' ? 'joinFull' : 'joinSales'}
      videoUrlOverride={
        salesSchedule.phase != 'during'
          ? 'https://www.youtube.com/watch?v=rFTezfDi8Bs'
          : 'https://www.youtube.com/watch?v=79zPueckkN4'
      }
      timerOverride={salesTimeOverride}
    />
  );
}

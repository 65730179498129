import React, {useState} from 'react';
import classNames from 'classnames';
import {Col, Collapse, Container, Row} from 'reactstrap';
import './SectionCL.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleDown} from '@fortawesome/pro-regular-svg-icons/faChevronCircleDown';
import {faChevronCircleUp} from '@fortawesome/pro-regular-svg-icons/faChevronCircleUp';

function AngleCL({
  angleType = 'slant',
  anglePosition = 'bottom',
  borderColor = 'white',
  color = 'black',
  className = undefined
}) {
  return (
    <div className={classNames(`angleWrap`, `angleCL-wrap-${anglePosition}`, className)}>
      {angleType === 'triangle' ? (
        <div className={classNames(`angleCL`, `angleCL-${anglePosition}`)}>
          <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
            <polygon
              points={anglePosition === 'top' ? '0,500 0,0 250,30 500,0 500,500' : '0,0 250,30 500,0'}
              className={classNames(`fill-polygon`, `fill-${color}`)}
            ></polygon>
          </svg>
        </div>
      ) : (
        <>
          <div className={classNames(`angleCL angleCL-${anglePosition}`)}>
            <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
              <polygon
                className={classNames(`fill-${color}`)}
                points={anglePosition === 'top' ? '0,0 0,50 500,50 500,49' : '0,0 500,0 500,50 0,1'}
              />
            </svg>
          </div>
          <div className={classNames(`angleCL angleCL-${anglePosition}-border`)}>
            <svg className="fill-svg" viewBox="0 0 500 50" preserveAspectRatio="none">
              <polygon
                className={classNames(`fill-${borderColor}`)}
                points={anglePosition === 'top' ? '0,0 0,50 500,50 500,44' : '0,0 500,0 500,50 0,6'}
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
}

export default function SectionCL({
  id,
  color,
  type = 'slant',
  order = 'default',
  className = undefined,
  innerClass = undefined,
  innerWrapClass = undefined,
  imgUrl = undefined,
  imgDescription = undefined,
  imgPosition = undefined,
  imgBgPosition = 'center',
  imgBgClass = undefined,
  imgBgWrapClass = undefined,
  mobileImageHidden = false,
  colClass = undefined,
  angle = 'bottom',
  angleBottomDisplay = 'visible',
  collapseHeadlines = undefined,
  collapseContent = undefined,
  collapseHeader = undefined,
  collapseHeaderBackgroundColor = 'black',
  children = null
}) {
  const angleTop = angle === 'top' || angle === 'both';
  const angleBottom = angle === 'bottom' || angle === 'both';

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((state) => !state);
  return (
    <section id={id} className={classNames(`sectionCL`, `background-${color}`, className)}>
      <div
        className={classNames(`sectionCL-inner-wrap`, `sectionCL-angleCL-bottom-${angleBottomDisplay}`, innerWrapClass)}
        style={{position: 'relative'}}
      >
        {imgPosition && imgUrl ? (
          <>
            <div className={classNames(`mobileImage`, mobileImageHidden && 'd-none')}>
              <img className="img-fill" alt={imgDescription ?? ''} src={imgUrl} />
            </div>
            <div className="sectionCL-image-wrap">
              {!collapseHeader && (
                <AngleCL
                  angleType={type}
                  anglePosition="top"
                  color={color}
                  className={classNames(mobileImageHidden && 'd-none', angle !== 'both' && 'd-lg-none')}
                />
              )}
              <div
                className={classNames(
                  `sectionCL-inner`,
                  `sectionCL-inner-image`,
                  `sectionCL-order-${order}`,
                  `sectionCL-angleCL-${angle}`,
                  innerClass,
                  mobileImageHidden && 'sectionCL-inner-image-mobile-hidden'
                )}
              >
                <Container style={{position: 'relative'}}>
                  <div className={classNames(`desktopImage`, `desktopImage-${imgPosition}`, `text-${color}`)}>
                    <img className="img-fill desktop-image" alt={imgDescription ?? ''} src={imgUrl} />
                  </div>
                  <Row className={classNames(`d-flex justify-content-${imgPosition === 'left' ? 'end' : 'start'}`)}>
                    <Col className={classNames(colClass ? colClass : 'col-12 col-lg-7')}>
                      {collapseHeader ? (
                        <>
                          <div className="text-center text-lg-left">{collapseHeadlines}</div>
                          <div className="d-none d-lg-block pt-4">{collapseContent}</div>
                        </>
                      ) : (
                        <>{children}</>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
              {angleBottom && (
                <div className={classNames(collapseHeader && `d-none d-lg-block`)}>
                  <AngleCL angleType={type} anglePosition="bottom" color={color} />
                </div>
              )}

              {collapseHeader && (
                <div className="d-lg-none">
                  <div
                    className={classNames(
                      `sectionCL-collapse-trigger d-flex align-items-center justify-content-between background-${collapseHeaderBackgroundColor} text-white py-4`
                    )}
                    onClick={toggle}
                  >
                    <Container>
                      <Row className="align-items-center">
                        <Col xs="9">{collapseHeader}</Col>
                        <Col xs="3">
                          <div className="sectionCL-collapse-header-icon d-flex align-items-center justify-content-end flex-column flex-lg-row text-h5 text-800">
                            <FontAwesomeIcon
                              className="sectionCL-collapse-icon mb-2 mb-lg-0 mr-lg-1"
                              // @ts-ignore
                              icon={isOpen ? faChevronCircleUp : faChevronCircleDown}
                            />
                            <div className="sectionCL-collapse-header-icon-text text-small text-uppercase">
                              {isOpen ? (
                                <>Close</>
                              ) : (
                                <>
                                  <span className="d-none d-lg-inline">Learn</span> More
                                </>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>

                  <Collapse isOpen={isOpen} className="d-lg-block">
                    <Container>
                      <Row>
                        <Col>
                          <div className="section-triangle-image-content-inner">{collapseContent}</div>
                        </Col>
                      </Row>
                    </Container>
                  </Collapse>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            {angleTop && <AngleCL angleType={type} anglePosition="top" color={color} />}
            {imgUrl && !imgPosition ? (
              <div
                className={classNames('sectionCL-background-image-wrap', imgBgWrapClass)}
                style={{position: 'absolute', clipPath: 'inset(0)'}}
              >
                <img
                  alt={imgDescription ?? ''}
                  src={imgUrl}
                  className={classNames('sectionCL-background-image', `img-fill`, imgBgClass)}
                />
              </div>
            ) : null}
            <div
              className={classNames(`sectionCL-inner sectionCL-order-${order} sectionCL-angleCL-${angle}`, innerClass)}
            >
              {children}
            </div>
            {angleBottom && <AngleCL angleType={type} anglePosition="bottom" color={color} />}
          </>
        )}
      </div>
    </section>
  );
}

import React, {useEffect, useState} from 'react';
import {Container, Col, Row, Button} from 'reactstrap';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import useBalanceText from 'utils/useBalanceText';
import useBeacon from 'utils/useBeacon';
import track from 'utils/track';

import PageMeta from 'components/PageMeta';
import Header from 'components/Elements/Header';
import SectionCL from 'components/ComponentLibrary/SectionCL';
import Webinar from 'components/Webinar';
import Video from 'components/Video';
import SalesCopy from 'components/SalesCopy';
import ModalExitScreening from 'components/Modals/ModalExitScreening';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Elements/Footer';
import RegistrationModal from 'components/Modals/RegistrationModal';

const COMPLETED_MASTERCLASS_EVENT = 'Completed Watching UL Masterclass';

export default function ScreeningIndex({replayOverride = false, bucketOverride = null, ...props}) {
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();
  const {bucket: userBucket, events = []} = useUserState();
  const {bucket: webinarBucket, report: webinarReport} = webinarSchedule;
  const [anchorScrolled, setAnchorScrolled] = useState(false);

  const segment = bucketOverride ?? webinarReport ?? 'prevent';

  const hasCompletedMasterclass = events.includes(COMPLETED_MASTERCLASS_EVENT);
  /*const delayReveal =
    (!replayOverride && hasCompletedMasterclass) ||
    webinarSchedule.phase === 'replay' ||
    webinarSchedule.phase === 'after';*/
  const delayReveal = true;

  const pageTitle = 'Watch the Mini-Masterclass';
  const pageDescription = 'The plant-based keys to a long and fabulously healthy life.';
  const ogImage = {
    prevent:
      'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Prevention_Optimization-Oriented-1200x1200-v20221110.jpg',
    reverse: 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Reversal-Oriented-1200x1200-v20221206.jpg'
  }[segment];
  const shareUrl = {
    prevent: 'https://longevity.foodrevolution.org/masterclass/prevent/',
    reverse: 'https://longevity.foodrevolution.org/masterclass/reverse/'
  }[segment];
  const facebookDescription =
    'Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer.';
  const twitterDescription =
    'Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer.';
  const twitterImg = {
    prevent: 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Prevention_Optimization-Oriented-1200x630-20221110.jpg',
    reverse: 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Reversal-Oriented-1200x630-20221206.jpg'
  }[segment];
  const pinterestDescription =
    'Watch this free mini-masterclass to learn the 5 healthy lifestyle habits of people who live an average of 12 to 14 years longer.';
  const pinterestImage = {
    prevent:
      'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Prevention_Optimization-Oriented-1000x1500-20221110.jpg',
    reverse: 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-Reversal-Oriented-1000x1500-20221206.jpg'
  }[segment];

  const productID = 'SC-UL';
  const productSku = 'SC-UL';
  const productName = 'Unlocking Longevity';
  const productSlug = 'ul-masterclass';
  const productValue = 67;
  const paymentPlanPrice = 0;

  useEffect(() => {
    track('Product Viewed', {
      product_id: productID,
      sku: productSku,
      name: productName,
      variant: productSlug,
      value: productValue,
      currency: 'usd'
    });
  });

  useEffect(() => {
    if (anchorScrolled) {
      setAnchorScrolled(true);
      if (window !== undefined && window.location.hash === '#join') {
        document.getElementById('offer-cta-1').scrollIntoView();
      }
    }
  }, [events, anchorScrolled]);

  const videoUrl = {
    prevent: 'https://www.youtube.com/watch?v=SZOTRIhQlxc',
    reverse: 'https://www.youtube.com/watch?v=sTRgY1CI-2g'
  }[segment];

  const videoLabel = {
    curious: 'UL - Masterclass - Prevent/Optimize-oriented',
    reverse: 'UL - Masterclass - Reversal-oriented'
  }[segment];

  //useBalanceText();
  useBeacon();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <Header
          className="background-black"
          style="dark"
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
        />

        <SectionCL
          id="screening-header-cta"
          color="white"
          order="first"
          type="triangle"
          angle={delayReveal ? 'bottom' : 'none'}
        >
          <Container>
            <Row>
              <Col className="text-center">
                <h2 className="section-heading mb-2">
                  {
                    {
                      prevent: <>Learn From The World’s Healthiest Elders</>,
                      reverse: <>How To Feel Better Than You Did 10 Years Ago</>
                    }[segment]
                  }
                </h2>
                <p className="balance-text mb-5">
                  with John Robbins, best-selling author of <i>Diet For A New America</i>
                </p>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                {replayOverride ? (
                  <Video url={videoUrl} label={videoLabel} />
                ) : (
                  <>
                    {/*
                      Disabling Webinar component for now, until black out bug can be confirmed as resolved.
                      <Webinar noun="Masterclass" label={videoLabel} />
                    */}
                    <Video url={videoUrl} label={videoLabel} playing />
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </SectionCL>

        {delayReveal && (
          <>
            <SalesCopy
              version="screening"
              productId={productID}
              productSku={productSku}
              productSlug={productSlug}
              productPrice={productValue}
              paymentPlanPrice={paymentPlanPrice}
            />
          </>
        )}
      </div>

      {replayOverride ? (
        <SectionCL id="replay-cta" color="purple" innerClass="text-center text-white pb-5" type="triangle" angle="none">
          <Container>
            <Row>
              <Col>
                <h4 className="section-heading">
                  Are you interested in enrolling in <i>Unlocking Longevity</i>?
                </h4>
                <h5>Click below to learn more:</h5>
                <Button className="btn-join btn-lg" color="cta" href="/join/" target="_blank">
                  Join Now!
                </Button>
              </Col>
            </Row>
          </Container>
        </SectionCL>
      ) : (
        <ModalExitScreening />
      )}

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
      </div>
      <RegistrationModal />
    </div>
  );
}

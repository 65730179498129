import React from 'react';
import {Col, Container, Row} from 'reactstrap';

export default function () {
  return (
    <div className="page-content p-5">
      <Container fluid>
        <Row>
          <Col>
            <p>404 - We couldn't find the page you're looking for!</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

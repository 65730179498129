import React from 'react';
import {ModalBody, Button} from 'reactstrap';
import {navigate} from '@reach/router';
import classNames from 'classnames';

import Modal from 'components/Modal';
import {useModal} from 'contexts/modal';
import OptInForm from 'components/Forms/OptInForm';

export default function OptinModal({disableNavigate = false, formProps = undefined}) {
  const modalProps = useModal();
  const [navigateOnClose, setNavigateOnClose] = React.useState(false);
  const onComplete = React.useCallback(() => {
    modalProps.toggle();
    !disableNavigate && navigate('/masterclass/watch/', {state: {fromEntry: formProps.entry}});
  }, []);
  const onStepComplete = React.useCallback((step) => step === 1 && setNavigateOnClose(true), []);
  const toggle = React.useCallback(() => {
    if (navigateOnClose && !disableNavigate) navigate('/masterclass/watch/', {state: {fromEntry: formProps.entry}});
    modalProps.toggle();
  }, [navigateOnClose]);
  return (
    <Modal isOpen={modalProps.isOpen} toggle={toggle} size="lg">
      <ModalBody className="px-4 pt-0 pb-4">
        <OptInForm
          onComplete={() => {
            onComplete();
          }}
          {...formProps}
        />
      </ModalBody>
    </Modal>
  );
}

export function OptinModalTrigger({btnText = 'Sign Up Now!', className = undefined}) {
  const modalProps = useModal();
  return (
    <Button color="cta" size="lg" className={classNames(`optin-button-trigger`, className)} onClick={modalProps.toggle}>
      {btnText}
    </Button>
  );
}

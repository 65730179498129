import React from 'react';
import {navigate} from '@reach/router';
import ContactInfoForm from 'components/Forms/OptInForm/components/ContactInfoForm';

import './OptinForm.scss';

export default function OptInForm({disableNavigate = false, contactFormProps = undefined}) {
  const onComplete = React.useCallback(() => {
    !disableNavigate && navigate('/masterclass/watch/');
  }, []);
  return (
    <ContactInfoForm
      onComplete={() => {
        onComplete();
      }}
      {...contactFormProps}
    />
  );
}

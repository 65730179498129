import React, {useCallback, useState} from 'react';
import {Button, Col, Row} from 'reactstrap';

import Modal from 'components/Modal';
import ProductImage from 'components/Elements/ProductImage';
import useExitIntent from 'utils/useExitIntent.js';

export default function ModalExitScreening(props) {
  const {isOpen, toggle} = useExitIntent(300000);
  return (
    <Modal className="modal-exit-intent modal-exit-intent-screening" isOpen={isOpen} toggle={toggle}>
      <Row className="text-center text-lg-left d-flex align-items-stretch m-0">
        <Col xs="12" lg="7" className="modal-exit-intent-copy order-lg-2 my-auto">
          <h4 className="section-heading mb-4">Don’t go yet!</h4>
          <p className="mb-0">
            I want you to have the same <br className="d-none d-md-block d-xl-none" />
            secrets to exceptionally healthy <br className="d-none d-lg-block d-xl-none" />
            aging <br className="d-none d-md-block d-lg-none" />
            that are found <br className="d-none d-xl-block" />
            in the places where people <br className="d-none d-md-block d-lg-none" />
            live the longest <br className="d-none d-lg-block" />
            and stay the healthiest.
          </p>
          <Row className="d-flex align-items-center mt-5">
            <Col xs="12" lg="6" className="mb-4 mb-lg-0 pr-lg-0">
              <Button
                className="text-uppercase text-white btn-exit-orange btn-exit-ul-screening"
                color="cta"
                onClick={toggle}
                block
              >
                Return to <br className="d-none d-lg-block d-xl-none" />
                the <br className="d-none d-xl-block" />
                Mini-Masterclass
              </Button>
            </Col>
            <Col xs="12" lg="6">
              <Button className="text-uppercase text-800 text-white" color="exit-gray" onClick={toggle} block>
                No thanks, I already know this info
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs="12" lg="5" className="landing-exit-image-col background-light-gray order-lg-1 d-flex flex-column">
          <div className="my-auto px-3 px-lg-1 py-5">
            <ProductImage />
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import MasterClassIndex from './prevent';

export default function (props) {
  useEffect(() => {
    navigate('/masterclass/prevent/' + location.search);
  }, []);
  return <MasterClassIndex />;
}

import React, {useEffect} from 'react';
import {Col, Container, Row} from 'reactstrap';
import queryString from 'query-string';
import {subscribeToCampaign} from 'react-controlled-ab/datalayers/vwo';

import {usePhaseState} from 'funnel-schedule/phase-context';
import {useUserState} from 'contexts/user';
import useBalanceText from 'utils/useBalanceText';
import useBeacon from 'utils/useBeacon';
import track from 'utils/track';

import PageMeta from 'components/PageMeta';
import Section from 'components/Elements/Section';
import HeaderVideo from 'components/Elements/HeaderVideo';
import CheckoutButton from 'components/CheckoutButton';
import CountdownTimer from 'components/Elements/CountdownTimer';
import {SalesDisclaimer} from 'components/SalesCta';
import SalesCopy from 'components/SalesCopy';
import ModalExitSales from 'components/Modals/ModalExitSales';
import ShareButtons from 'components/Social/ShareButtons';
import Footer from 'components/Elements/Footer';
import SectionCL from 'components/ComponentLibrary/SectionCL';

export default function SalesIndex({
  noExitIntent = false,
  noFixedVideo = false,
  ctaOverride = null,
  timerOverride = null,
  videoUrlOverride = null,
  ctaNoTimer = false,
  variant = null,
  bucketOverride = null,
  ...props
}) {
  const {email, registrationId} = useUserState();
  const {
    phases: {
      default: defaultSchedule = {
        phase: 'before'
      },
      sales: salesSchedule = {
        phase: 'during'
      },
      webinar: webinarSchedule = {
        phase: 'before'
      }
    }
  } = usePhaseState();
  const {bucket: userBucket = []} = useUserState();
  const {bucket: webinarBucket} = webinarSchedule;
  const segment = bucketOverride ?? webinarBucket ?? userBucket ?? 'prevent';

  const qs = typeof window !== 'undefined' ? queryString.parse(window.location.search) : {};
  const {t} = qs.t ? qs : {};

  const pageTitle = 'Unlocking Longevity';
  const pageDescription = 'The Plant-Based Keys to a Long and Fabulously Healthy Life';
  const ogImage = 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-sales-1200x1200-v20221110.jpg';
  const shareUrl = 'https://longevity.foodrevolution.org/join/';
  const facebookDescription =
    'Ditch the stereotype of aging miserably. Learn what the world’s longest-living people eat and do.';
  const twitterDescription =
    'Ditch the stereotype of aging miserably. Learn what the world’s longest-living people eat and do.';
  const twitterImg = 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-sales-1200x630-20221110.jpg';
  const pinterestDescription =
    'Ditch the stereotype of aging miserably. Learn what the world’s longest-living people eat and do.';
  const pinterestImage = 'https://cdn.foodrevolution.org/ul/og/ul-opengraph-sales-1000x1500-20221110.jpg';

  const isCartClose = new Date(webinarSchedule.endOfSalesDay * 1000 - 86400000).getTime() < new Date().getTime();
  const salesEnd = new Date(webinarSchedule.endOfSalesDay * 1000).getTime();
  const salesTimerDate = timerOverride ? timerOverride : salesEnd;

  const salesFullPrice =
    (!email && !registrationId && !ctaOverride) ||
    (!ctaOverride && webinarSchedule.phase === 'after') ||
    ctaOverride === 'joinFull';

  const productID = 'SC-UL';
  const productSku = 'SC-UL';
  const productName = 'Unlocking Longevity';
  const productSlug = t || !salesFullPrice ? 'ul-special' : 'ul';
  const productValue = t || !salesFullPrice ? 97 : 197;
  const paymentPlanPrice = t || !salesFullPrice ? 0 : 0;

  useEffect(() => {
    track('Product Viewed', {
      product_id: productID,
      sku: productSku,
      name: productName,
      variant: productSlug,
      value: productValue,
      currency: 'usd'
    });
  }, [salesFullPrice]);

  //useBalanceText();
  useBeacon();
  return (
    <div className="page d-flex flex-column">
      <PageMeta title={pageTitle} description={pageDescription} url={shareUrl} ogImage={ogImage} />

      <div className="page-content">
        <SectionCL id="join-header" innerClass="text-white text-center" type="triangle" color="black" order="first">
          <Container>
            <Row>
              <Col>
                <h2 className="section-heading mb-4">
                  {
                    {
                      prevent: <>Learn From The World’s Healthiest Elders</>,
                      reverse: <>How To Feel Better Than You Did 10 Years Ago</>
                    }[segment]
                  }
                </h2>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col lg="10" className="video-col px-0 px-sm-3">
                <h5 className="section-heading">Watch this video:</h5>
                <HeaderVideo
                  url={
                    videoUrlOverride
                      ? videoUrlOverride
                      : t || !salesFullPrice
                      ? {
                          prevent: 'https://www.youtube.com/watch?v=79zPueckkN4',
                          reverse: 'https://www.youtube.com/watch?v=9JCrV-SxYVE'
                        }[segment]
                      : {
                          prevent: 'https://www.youtube.com/watch?v=rFTezfDi8Bs',
                          reverse: 'https://www.youtube.com/watch?v=x_zSrPWb4rc'
                        }[segment]
                  }
                  noFixed={noFixedVideo}
                  label="UL - Sales"
                  sku={productSku}
                  slug={productSlug}
                  price={productValue}
                  salesContent={<h3 className="mt-0 mb-3">Enroll in Unlocking Longevity</h3>}
                  playing
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="header-cta-wrap mt-5">
                  {t || !salesFullPrice ? (
                    <>
                      <h2 className="section-heading mb-3">
                        Right Now Just <del>$197</del> $97
                      </h2>
                      {!ctaNoTimer ? (
                        <>
                          <h5 className="mt-4">Your discount expires in:</h5>
                          <CountdownTimer time={salesTimerDate} className="countdown-light" />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <h2 className="section-heading">Right Now $197</h2>
                    </>
                  )}
                  <CheckoutButton sku={productSku} price={productValue} slug={productSlug} className="my-3" />
                  <SalesDisclaimer iconColor="white" />
                </div>
              </Col>
            </Row>
          </Container>
        </SectionCL>

        <SalesCopy
          version="sales"
          productId={productID}
          productSku={productSku}
          productSlug={productSlug}
          productPrice={productValue}
          paymentPlanPrice={paymentPlanPrice}
          variant={variant}
          salesFullPrice={salesFullPrice}
          ctaOverride={ctaOverride}
          ctaNoTimer={ctaNoTimer}
          timerOverride={timerOverride}
          bucketOverride={bucketOverride}
        />
      </div>

      {noExitIntent ? null : <ModalExitSales />}

      <div className="footer-content mt-auto">
        <Footer />
        <ShareButtons
          shareUrl={shareUrl}
          shareImg={ogImage}
          facebookDescription={facebookDescription}
          twitterDescription={twitterDescription}
          twitterImg={twitterImg}
          pinterestDescription={pinterestDescription}
          pinterestImage={pinterestImage}
          floating
        />
      </div>
    </div>
  );
}
